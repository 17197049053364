// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

export const ROOTS = {
  home: '/',
  test: '/test',
  auth: '/auth',
  dashboard: {
    root: '/dashboard',
    employee: '/dashboard/employee',
    admin: '/dashboard/admin',
    supervisor: '/dashboard/supervisor',
    contractor: '/dashboard/contractor',
  },
};

const ROOTS_AUTH = ROOTS.auth;
const ROOTS_DASHBOARD = ROOTS.dashboard.root;
// TODO: Update this to point just to ROOTS_DASHBOARD
const ROOTS_USER_TYPES_DASHBOARD = ROOTS.dashboard;

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  resetPasswordSub: path(ROOTS_AUTH, '/new-password-sub'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    vendor: path(ROOTS_DASHBOARD, '/vendor'),
    contractor: {
      root: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/'),
      schedule: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/schedule'),
      overview: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/overview'),
      },
      timesheet: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/timesheet'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/timesheet/list'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.contractor, `/timesheet/${id}`),
      },
      worksheet: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/worksheet'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/worksheet/list'),
        new: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/worksheet/new'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.contractor, `/worksheet/${id}`),
      },
      jobs: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/jobs'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/jobs/list'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.contractor, `/jobs/${id}`),
      },
      forms: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/forms'),
        expense: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/forms/expense'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/forms/expense/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.contractor, `/forms/expense/${id}`),
        },
        fuelLogs: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/forms/fuel-logs'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/forms/fuel-logs/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.contractor, `/forms/fuel-logs/${id}`),
        },
        payrollRequest: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/forms/payroll-request'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/forms/payroll-request/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.contractor, `/forms/payroll-request/${id}`),
        },
        preTripInspection: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/forms/pre-trip-inspection'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/forms/pre-trip-inspection/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.contractor, `/forms/pre-trip-inspection/${id}`),
        },
        suppliesRequest: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/forms/supplies-request'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/forms/supplies-request/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.contractor, `/forms/supplies-request/${id}`),
        },
        repairRequests: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/forms/repair-request'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/forms/repair-request/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.contractor, `/forms/repair-request/${id}`),
        },
      },
      safetyForm: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/safety-form'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/safety-form/list'),
        new: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/safety-form/create-new'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.contractor, `/safety-form/${id}`),
      },
      settings: path(ROOTS_USER_TYPES_DASHBOARD.contractor, '/settings'),
    },
    employee: {
      root: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/'),
      schedule: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/schedule'),
      overview: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/overview'),
      },
      timesheet: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/timesheet'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/timesheet/list'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.employee, `/timesheet/${id}`),
      },
      worksheet: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/worksheet'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/worksheet/list'),
        new: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/worksheet/new'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.employee, `/worksheet/${id}`),
      },
      jobs: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/jobs'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/jobs/list'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.employee, `/jobs/${id}`),
      },
      forms: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/forms'),
        expense: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/forms/expense'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/forms/expense/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.employee, `/forms/expense/${id}`),
        },
        fuelLogs: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/forms/fuel-logs'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/forms/fuel-logs/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.employee, `/forms/fuel-logs/${id}`),
        },
        payrollRequest: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/forms/payroll-request'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/forms/payroll-request/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.employee, `/forms/payroll-request/${id}`),
        },
        preTripInspection: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/forms/pre-trip-inspection'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/forms/pre-trip-inspection/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.employee, `/forms/pre-trip-inspection/${id}`),
        },
        suppliesRequest: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/forms/supplies-request'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/forms/supplies-request/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.employee, `/forms/supplies-request/${id}`),
        },
        repairRequests: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/forms/repair-request'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/forms/repair-request/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.employee, `/forms/repair-request/${id}`),
        },
      },
      safetyForm: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/safety-form'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/safety-form/list'),
        new: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/safety-form/create-new'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.employee, `/safety-form/${id}`),
      },
      settings: path(ROOTS_USER_TYPES_DASHBOARD.employee, '/settings'),
    },
    supervisor: {
      root: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/'),
      schedule: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/schedule'),
      overview: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/overview'),
      },
      timesheet: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/timesheet'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/timesheet/list'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.supervisor, `/timesheet/${id}`),
      },
      worksheet: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/worksheet'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/worksheet/list'),
        new: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/worksheet/new'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.supervisor, `/worksheet/${id}`),
      },
      jobs: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/jobs'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/jobs/list'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.supervisor, `/jobs/${id}`),
      },
      forms: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/forms'),
        expense: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/forms/expense'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/forms/expense/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.supervisor, `/forms/expense/${id}`),
        },
        fuelLogs: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/forms/fuel-logs'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/forms/fuel-logs/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.supervisor, `/forms/fuel-logs/${id}`),
        },
        payrollRequest: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/forms/payroll-request'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/forms/payroll-request/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.supervisor, `/forms/payroll-request/${id}`),
        },
        preTripInspection: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/forms/pre-trip-inspection'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/forms/pre-trip-inspection/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.supervisor, `/forms/pre-trip-inspection/${id}`),
        },
        suppliesRequest: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/forms/supplies-request'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/forms/supplies-request/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.supervisor, `/forms/supplies-request/${id}`),
        },
        repairRequests: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/forms/repair-request'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/forms/repair-request/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.supervisor, `/forms/repair-request/${id}`),
        },
      },
      safetyForm: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/safety-form'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/safety-form/list'),
        new: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/safety-form/create-new'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.supervisor, `/safety-form/${id}`),
      },
      settings: path(ROOTS_USER_TYPES_DASHBOARD.supervisor, '/settings'),
    },
    admin: {
      root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/'),
      schedule: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/schedule'),
      overview: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/overview'),
      },
      invoicing: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/invoicing'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/invoicing/list'),
        new: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/invoicing/create-new'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/invoicing/${id}`),
      },
      system: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/system'),
        asset: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/system/asset'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/system/asset/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/system/asset/${id}`),
        },
        communities: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/system/communities'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/system/communities/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/system/communities/${id}`),
        },
        vendor: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/system/vendor'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/system/vendor/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/system/vendor/${id}`),
        },
      },
      user: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/user'),
        new: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/user/new'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/user/list'),
        cards: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/user/cards'),
        profile: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/user/profile'),
        account: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/user/account'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/user/${id}`),
        edit: (name) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/user/${name}/edit`),
        demoEdit: path(ROOTS_USER_TYPES_DASHBOARD.admin, `/user/reece-chung/edit`),
      },
      applicant: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/applicant'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/applicant/list'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/applicant/${id}`),
      },
      timesheet: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/timesheet'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/timesheet/list'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/timesheet/${id}`),
      },
      worksheet: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/worksheet'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/worksheet/list'),
        new: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/worksheet/new'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/worksheet/${id}`),
      },
      vendor: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/vendor'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/vendor/list'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/vendor/${id}`),
      },
      jobs: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/jobs'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/jobs/list'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/jobs/${id}`),
      },
      reports: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/reports'),
        billingReport: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/reports/billing'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/reports/billing/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/reports/billing/${id}`),
        },
        expensesReport: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/reports/expenses'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/reports/expenses/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/reports/expenses/${id}`),
        },
        sitesReport: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/reports/sites'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/reports/sites/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/reports/sites/${id}`),
        },
        timesheetReport: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/reports/timesheet'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/reports/timesheet/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/reports/timesheet/${id}`),
        },
        costingReport: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/reports/costing'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/reports/costing/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/reports/costing/${id}`),
        },
      },
      pricing: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/pricing'),
        material: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/pricing/material'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/pricing/material/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/pricing/material/${id}`),
        },
        service: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/pricing/service'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/pricing/service/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/pricing/service/${id}`),
        },
        job: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/pricing/job'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/pricing/job/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/pricing/job/${id}`),
        },
      },
      forms: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/forms'),
        expense: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/forms/expense'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/forms/expense/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/forms/expense/${id}`),
        },
        fuelLogs: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/forms/fuel-logs'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/forms/fuel-logs/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/forms/fuel-logs/${id}`),
        },
        payrollRequest: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/forms/payroll-request'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/forms/payroll-request/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/forms/payroll-request/${id}`),
        },
        preTripInspection: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/forms/pre-trip-inspection'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/forms/pre-trip-inspection/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/forms/pre-trip-inspection/${id}`),
        },
        suppliesRequest: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/forms/supplies-request'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/forms/supplies-request/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/forms/supplies-request/${id}`),
        },
        repairRequests: {
          root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/forms/repair-request'),
          list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/forms/repair-request/list'),
          view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/forms/repair-request/${id}`),
        },
      },
      safetyForm: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/safety-form'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/safety-form/list'),
        new: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/safety-form/create-new'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/safety-form/${id}`),
      },
      client: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/client'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/client/list'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/client/${id}`),
      },
      hiring: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/hiring'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/hiring/list'),
        new: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/hiring/new'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/hiring/${id}`),
      },
      asset: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/asset'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/asset/list'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/asset/${id}`),
      },
      communities: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/communities'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/communities/list'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/communities/${id}`),
      },
      properties: {
        root: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/properties'),
        list: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/properties/list'),
        view: (id) => path(ROOTS_USER_TYPES_DASHBOARD.admin, `/properties/${id}`),
      },
      settings: path(ROOTS_USER_TYPES_DASHBOARD.admin, '/settings'),
    },

    schedule: path(ROOTS_DASHBOARD, '/schedule'),
    permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  },
};
