import { createSlice } from '@reduxjs/toolkit';
import { SITE_ENDPOINT } from 'src/constants/apiConstants';
import { getHeaders, isJSON } from 'src/utils';
// utils
import axios from 'src/utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  sites: [],
  site: null,
  activeSites: [],
};

const slice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SITES
    getSitesSuccess(state, action) {
      const sites = action.payload?.map((site) => ({
        ...site,
        clients: isJSON(site?.clients) ? JSON.parse(site?.clients) : site?.clients,
      }));
      state.isLoading = false;
      state.sites = sites;
      state.activeSites = sites?.filter((site) => site?.is_active);
    },

    // GET SITE BY ID
    getSiteByIdSuccess(state, action) {
      state.isLoading = false;
      state.site = action.payload;
    },

    // ADD SITE
    addSiteSuccess(state, action) {
      const sites = [...state.sites, action.payload];
      state.sites = sites;
      state.activeSites = sites?.filter((site) => site?.is_active);
    },

    // UPDATE SITE BY ID
    updateSiteByIdSuccess(state, action) {
      state.site = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getSites() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(SITE_ENDPOINT);
      dispatch(slice.actions.getSitesSuccess(response?.data?.sites || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSiteById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${SITE_ENDPOINT}/${id}`);
      dispatch(slice.actions.getSiteByIdSuccess(response?.data?.site));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addSite(params) {
  return async () => {
    const headers = getHeaders();
    const response = await axios.post(SITE_ENDPOINT, params, { headers });
    dispatch(slice.actions.addSiteSuccess(response?.data?.site));
  };
}

// ----------------------------------------------------------------------

export function updateSiteById(id, params) {
  return async () => {
    const headers = getHeaders();
    const response = await axios.put(`${SITE_ENDPOINT}/${id}`, params, { headers });
    dispatch(slice.actions.updateSiteByIdSuccess(response?.data?.site));
  };
}
