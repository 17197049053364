import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const logo = (
    <Box component="img" src="/logo/logo_full.svg" sx={{ width: 225, height: 'auto', cursor: 'pointer', ...sx }} />
  );

  const loadingStateLogo = (
    <Box
      component="img"
      src="/logo/TNN_Logo_Stacked.svg"
      sx={{ width: 225, height: 'auto', cursor: 'pointer', ...sx }}
    />
  );

  if (disabledLink) {
    return <>{loadingStateLogo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
