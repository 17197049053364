import { createSlice } from '@reduxjs/toolkit';
import { LIST_ALL_INVOICES } from 'src/constants/apiConstants';

// utils
import axios from 'src/utils/axios';
import { getHeaders } from 'src/utils';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  invoices: [],
  invoice: null,
  invoiceNumber: 1,
};

const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET INVOICE
    getInvoiceByIdSuccess(state, action) {
      state.isLoading = false;
      state.invoice = action.payload;
    },

    // ADD INVOICE
    addInvoiceSuccess(state, action) {
      state.invoices = [...state.invoices, action.payload];
    },

    // UPDATE INVOICE BY ID
    updateInvoiceByIdSuccess(state, action) {
      state.invoice = action.payload;
    },

    // DELETE INVOICE BY ID
    deleteInvoiceByIdSuccess(state, action) {
      state.invoices = state.invoices?.filter((invoice) => invoice?.id !== action.payload);
    },

    // INVOICE Number
    getInvoiceNumberSuccess(state, action) {
      state.isLoading = false;
      state.invoiceNumber = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getInvoices() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(LIST_ALL_INVOICES);
      dispatch(slice.actions.getInvoicesSuccess(response?.data?.invoices || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addInvoice(params) {
  return async () => {
    const headers = getHeaders();
    await axios.post(LIST_ALL_INVOICES, params, { headers });
  };
}

// ----------------------------------------------------------------------

export function getInvoiceById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${LIST_ALL_INVOICES}/${id}`);
      dispatch(slice.actions.getInvoiceByIdSuccess(response?.data?.invoice));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteInvoiceById(id) {
  return async () => {
    await axios.delete(`${LIST_ALL_INVOICES}/${id}`);
    dispatch(slice.actions.deleteInvoiceByIdSuccess(id));
  };
}

// ----------------------------------------------------------------------

export function updateInvoiceById(id, params) {
  return async () => {
    const headers = getHeaders();
    await axios.put(`${LIST_ALL_INVOICES}/${id}`, params, { headers });
  };
}

export function getInvoiceNumber() {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await axios.get(`${LIST_ALL_INVOICES}/invoice-number`);
      dispatch(slice.actions.getInvoiceNumberSuccess(response?.data?.data?.invoiceCount));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
