import { createSlice } from '@reduxjs/toolkit';
import {
  JOB_ENDPOINT,
  JOB_EXTRA_ENDPOINT,
  JOB_GARBAGE_ENDPOINT,
  JOB_NOTES_ENDPOINT,
  JOB_PHOTO_ENDPOINT,
} from 'src/constants/apiConstants';
import { getHeaders } from 'src/utils';
// utils
import axios from 'src/utils/axios';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  jobs: [],
  job: null,
  selectedJobId: null,
};

const slice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // SELECT EVENT
    selectJob(state, action) {
      const jobId = action.payload;
      state.isOpenModal = true;
      state.selectedJobId = jobId;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET JOBS
    getJobsSuccess(state, action) {
      state.isLoading = false;
      state.jobs = action.payload;
    },

    // GET JOB
    getJobSuccess(state, action) {
      state.isLoading = false;
      state.job = {
        ...action.payload,
        workers: JSON.parse(action.payload.workers),
        neighborhood: JSON.parse(action.payload.neighborhood)?.[0],
        extras: action.payload.extras ? JSON.parse(action.payload.extras) : [],
        photos: action.payload.photos ? JSON.parse(action.payload.photos) : [],
        notes: action.payload.notes ? JSON.parse(action.payload.notes) : [],
        garbage_collected: action.payload.garbage_collected ? JSON.parse(action.payload.garbage_collected) : [],
      };
    },

    addExtraSuccess(state, action) {
      const newJobIndex = state.jobs.findIndex((j) => j.id === action.payload.job_id);
      state.jobs[newJobIndex] = {
        ...state.jobs[newJobIndex],
        extras: [...state.jobs[newJobIndex].extras, action.payload],
      };
    },

    editExtraSuccess(state, action) {
      const newJobIndex = state.jobs.findIndex((j) => j.id === action.payload.job_id);
      const newExtras = state.jobs[newJobIndex].extras.map((e) => {
        if (e.id === action.payload.id) {
          return action.payload;
        }
        return e;
      });

      state.jobs[newJobIndex] = { ...state.jobs[newJobIndex], extras: newExtras };
    },

    // ADD JOB
    addJobSuccess(state, action) {
      state.jobs = [...state.jobs, { ...action.payload, workers: JSON.parse(action.payload?.workers) }];
    },

    // EDIT JOB
    editJobSuccess(state, action) {
      state.jobs = state?.jobs?.map((job) => {
        if (job?.id === action?.payload?.id) return { ...action.payload, workers: JSON.parse(action.payload?.workers) };
        return job;
      });
    },
  },
});

// Reducer
export default slice.reducer;

export const { selectJob } = slice.actions;

// ----------------------------------------------------------------------

export function getJobs() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(JOB_ENDPOINT);
      const jobs = response?.data?.jobs.map((j) => ({
        ...j,
        workers: JSON.parse(j.workers),
        neighborhood: JSON.parse(j.neighborhood)?.[0],
        extras: j.extras ? JSON.parse(j.extras) : [],
        photos: j.photos ? JSON.parse(j.photos) : [],
        notes: j.notes ? JSON.parse(j.notes) : [],
        garbage_collected: j.garbage_collected ? JSON.parse(j.garbage_collected) : [],
      }));
      dispatch(slice.actions.getJobsSuccess(jobs || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getJobById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${JOB_ENDPOINT}/${id}`);
      dispatch(slice.actions.getJobSuccess(response?.data?.job));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addJob(params) {
  return async () => {
    const headers = getHeaders();
    const response = await axios.post(JOB_ENDPOINT, params, { headers });
    dispatch(slice.actions.addJobSuccess(response?.data?.job));
  };
}

// ----------------------------------------------------------------------

export function addExtras(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const headers = getHeaders();
      await axios.post(JOB_EXTRA_ENDPOINT, params, { headers });
      dispatch(slice.actions.addExtraSuccess(params));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function editJob(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const headers = getHeaders();
      const response = await axios.put(JOB_ENDPOINT, params, { headers });
      dispatch(slice.actions.editJobSuccess(response?.data?.job));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function editExtras(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const headers = getHeaders();
      const response = await axios.put(`${JOB_EXTRA_ENDPOINT}/${params.job_id}`, params, { headers });
      dispatch(slice.actions.editExtraSuccess(response?.data?.extra[0]));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function insertGarbage(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const headers = getHeaders();
      await axios.post(JOB_GARBAGE_ENDPOINT, params, { headers });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addJobNote(params) {
  return async () => {
    try {
      const headers = getHeaders();
      await axios.post(JOB_NOTES_ENDPOINT, params, { headers });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function editJobNoteById(id, params) {
  return async () => {
    try {
      const headers = getHeaders();
      await axios.put(`${JOB_NOTES_ENDPOINT}/${id}`, params, { headers });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteJobNoteById(id) {
  return async () => {
    try {
      await axios.delete(`${JOB_NOTES_ENDPOINT}/${id}`);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addJobPhoto(params) {
  return async () => {
    try {
      const headers = getHeaders();
      await axios.post(JOB_PHOTO_ENDPOINT, params, { headers });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteJobPhotoById(id) {
  return async () => {
    try {
      await axios.delete(`${JOB_PHOTO_ENDPOINT}/${id}`);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
