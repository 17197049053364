import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { WORKSHEET_ENDPOINT, WORKSHEET_NOTES_ENDPOINT, WORKSHEET_PHOTO_ENDPOINT } from 'src/constants/apiConstants';
// utils
import axios from 'src/utils/axios';
import { getHeaders } from 'src/utils';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  worksheets: [],
  worksheet: null,
};

const slice = createSlice({
  name: 'worksheet',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET WORKSHEETS SUCCESS
    getWorksheetsSuccess(state, action) {
      state.isLoading = false;
      state.worksheets = action.payload;
    },

    // ADD WORKSHEET SUCCESS
    addWorksheetSuccess(state, action) {
      state.worksheets = [...state.worksheets, ...action.payload];
    },

    // UPDATE WORKSHEET BY ID SUCCESS
    updateWorksheetByIdSuccess(state, action) {
      state.worksheet = action.payload;
    },

    // GET WORKSHEET BY ID
    getWorksheetByIdSuccess(state, action) {
      state.isLoading = false;
      state.worksheet = {
        ...action.payload,
        extra_charges: action.payload?.extra_charges ? JSON.parse(action.payload.extra_charges) : [],
        pickup_details: action.payload?.pickup_details ? JSON.parse(action.payload.pickup_details) : [],
        hazard_prevention: action.payload?.hazard_prevention
          ? JSON.parse(action.payload.hazard_prevention).map((item) => {
              if (item.hazard_time) {
                const hazard_time = moment(item?.hazard_time, 'HH:mm:ss.SSSSSS');
                return { ...item, hazard_time };
              }
              return item;
            })
          : [],
        job_descriptions: action.payload?.job_descriptions ? JSON.parse(action.payload.job_descriptions) : [],
        notes: action.payload?.notes ? JSON.parse(action.payload.notes) : [],
        photos: action.payload?.photos ? JSON.parse(action.payload.photos) : [],
      };
    },

    // REMOVE WORKSHEET BY ID SUCCESS
    deleteWorksheetByIdSuccess(state, action) {
      state.worksheets = state.worksheets?.filter((worksheet) => worksheet?.id !== action.payload);
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getWorksheets() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(WORKSHEET_ENDPOINT);
      dispatch(slice.actions.getWorksheetsSuccess(response?.data?.worksheets || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getWorksheetById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${WORKSHEET_ENDPOINT}/${id}`);
      dispatch(slice.actions.getWorksheetByIdSuccess(response?.data?.worksheet || {}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addWorksheet(params) {
  return async () => {
    const headers = getHeaders();
    await axios.post(WORKSHEET_ENDPOINT, params, { headers });
  };
}

// ----------------------------------------------------------------------

export function updateWorksheetById(id, params) {
  return async () => {
    try {
      const headers = getHeaders();
      const response = await axios.put(`${WORKSHEET_ENDPOINT}/${id}`, params, { headers });
      dispatch(slice.actions.updateWorksheetByIdSuccess(response?.data?.worksheet || {}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteWorksheetById(id) {
  return async () => {
    try {
      await axios.delete(`${WORKSHEET_ENDPOINT}/${id}`);
      dispatch(slice.actions.deleteWorksheetByIdSuccess(id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addWorksheetPhoto(params) {
  return async () => {
    try {
      const headers = getHeaders();
      await axios.post(WORKSHEET_PHOTO_ENDPOINT, params, { headers });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteWorksheetPhotoById(id) {
  return async () => {
    try {
      await axios.delete(`${WORKSHEET_PHOTO_ENDPOINT}/${id}`);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addWorksheetNote(params) {
  return async () => {
    try {
      const headers = getHeaders();
      await axios.post(WORKSHEET_NOTES_ENDPOINT, params, { headers });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function EditWorksheetNoteById(id, params) {
  return async () => {
    try {
      const headers = getHeaders();
      await axios.put(`${WORKSHEET_NOTES_ENDPOINT}/${id}`, params, { headers });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteWorksheetNoteById(id) {
  return async () => {
    try {
      await axios.delete(`${WORKSHEET_NOTES_ENDPOINT}/${id}`);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
