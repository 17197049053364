import { createSlice } from '@reduxjs/toolkit';
import { VENDOR_ASSETS, VENDOR_ENDPOINT } from 'src/constants/apiConstants';
import { getHeaders } from 'src/utils';
// utils
import axios from 'src/utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  vendors: [],
  vendor: null,
  vendorAssets: [],
};

const slice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET VENDORS
    getVendorsSuccess(state, action) {
      state.isLoading = false;
      state.vendors = action.payload;
    },

    // GET VENDOR BY ID
    getVendorSuccess(state, action) {
      state.isLoading = false;
      state.vendor = action.payload;
    },

    // UPDATE VENDOR BY ID
    updateVendorByIdSuccess(state, action) {
      state.vendor = action.payload;
    },

    // GET VENDOR ASSETS
    getVendorAssestsSuccess(state, action) {
      state.isLoading = false;
      state.vendorAssets = action.payload;
    },

    // DELETE VENDOR BY ID SUCCESS
    deleteVendorByIdSuccess(state, action) {
      state.vendors = state.vendors?.filter((vendor) => vendor?.id !== action.payload);
    },

    // ADD VENDOR SUCCESS
    addVendorSuccess(state, action) {
      state.vendors = [...state.vendors, action.payload];
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getVendors() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(VENDOR_ENDPOINT);
      dispatch(slice.actions.getVendorsSuccess(response?.data?.vendors || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getVendorById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${VENDOR_ENDPOINT}/${id}`);
      dispatch(slice.actions.getVendorSuccess(response?.data?.vendor));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateVendorById(id, data) {
  return async () => {
    try {
      const headers = getHeaders();
      const response = await axios.put(`${VENDOR_ENDPOINT}/${id}`, data, { headers });
      dispatch(slice.actions.updateVendorByIdSuccess(response?.data?.vendor?.[0]));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getVendorAssets(id) {
  return async () => {
    try {
      const response = await axios.get(`${VENDOR_ASSETS}/${id}`);
      dispatch(slice.actions.getVendorAssestsSuccess(response?.data?.assets || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteVendorById(id) {
  return async () => {
    await axios.delete(`${VENDOR_ENDPOINT}/${id}`);
    dispatch(slice.actions.deleteVendorByIdSuccess(id));
  };
}

// ----------------------------------------------------------------------

export function addVendor(params) {
  return async () => {
    const headers = getHeaders();
    const response = await axios.post(VENDOR_ENDPOINT, params, { headers });
    dispatch(slice.actions.addVendorSuccess(response?.data?.vendor?.[0]));
  };
}
