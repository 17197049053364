import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Row from '../Row';

RFHTimeRange.propTypes = {
  time_in_name: PropTypes.string.isRequired,
  time_out_name: PropTypes.string.isRequired,
};

export default function RFHTimeRange({ time_in_name, time_out_name, ...other }) {
  const { control, watch, setError, clearErrors } = useFormContext();
  const values = watch();

  const time_in = values[time_in_name];
  const time_out = values[time_out_name];

  useEffect(() => {
    const endTimeBeforeStartTime = time_in && time_out ? moment(time_out).isBefore(moment(time_in)) : false;

    if (endTimeBeforeStartTime) {
      setError(time_out_name, { message: 'End time must be later than start time' });
    } else {
      clearErrors(time_out_name);
    }
  }, [time_in, time_out, time_out_name, setError, clearErrors]);

  return (
    <Row>
      <Controller
        name={time_in_name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <MobileTimePicker
            {...field}
            onChange={field.onChange}
            label="Time in"
            inputFormat="hh:mm a"
            renderInput={(params) => <TextField {...params} fullWidth error={!!error} helperText={error?.message} />}
            {...other}
          />
        )}
      />
      <Controller
        name={time_out_name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <MobileTimePicker
            {...field}
            onChange={field.onChange}
            label="time out"
            inputFormat="hh:mm a"
            renderInput={(params) => <TextField {...params} fullWidth error={!!error} helperText={error?.message} />}
            {...other}
          />
        )}
      />
    </Row>
  );
}
