import { isEmpty } from 'lodash';
import moment from 'moment';
import { JOB_TYPE_LABELS, VEHICLE } from 'src/utils/constants';
import * as Yup from 'yup';

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
const zipCodeRegExp = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
const alphaNumericRegExp = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/;

export const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});

export const RegisterSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});

export const VerifyCodeSchema = Yup.object().shape({
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const UserSettingsSchema = Yup.object().shape(
  {
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    cell_number: Yup.string().when('cell_number', {
      is: (value) => value?.length > 0,
      then: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
      otherwise: Yup.string(),
    }),
    postal_code: Yup.string().when('postal_code', {
      is: (value) => value?.length > 0,
      then: Yup.string().matches(zipCodeRegExp, 'Postal / Zip Code is not valid'),
      otherwise: Yup.string(),
    }),
  },
  [
    ['cell_number', 'cell_number'],
    ['postal_code', 'postal_code'],
  ]
);

export const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
});

export const VerifyOTPSchema = Yup.object().shape({
  code1: Yup.string().required('Code is required'),
  code2: Yup.string().required('Code is required'),
  code3: Yup.string().required('Code is required'),
  code4: Yup.string().required('Code is required'),
  code5: Yup.string().required('Code is required'),
  code6: Yup.string().required('Code is required'),
});

export const ClientSchema = Yup.object().shape({
  client_name: Yup.string().required('Company name is required'),
  address: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  postal_code: Yup.string()
    .required('Postal / Zip Code is required')
    .matches(zipCodeRegExp, 'Postal / Zip Code is not valid'),
  type: Yup.string().required('type is required'),
  homes_per_year: Yup.number().nullable(),
  business_phone: Yup.string()
    .required('Business Phone Number is required')
    .matches(phoneRegExp, 'Business Phone number is not valid'),
  contact_name: Yup.string().required('Contact name is required'),
  contact_email: Yup.string().email('Invalid email address').required('Email is required'),
  services: Yup.array().test('is-required', 'Services are required', (value) => !isEmpty(value)),
});

export const SiteSchema = Yup.object().shape({
  address: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  postal_code: Yup.string()
    .trim()
    .nullable()
    .matches(zipCodeRegExp, { message: 'Postal Code is not valid', excludeEmptyString: true }),
  house_style: Yup.string(),
  house_sq_ft: Yup.string()
    .test('is-greater', 'Must be greater than 0', (value, schema) => {
      if (!value) return true;
      const currentValue = value === '' ? 0 : parseInt(value, 10);
      return currentValue > 0;
    })
    .nullable(),
  yard_size: Yup.string(),
  lot_type: Yup.string(),
});

export const NewApplicantFormSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  phone: Yup.string().required('Phone Number is required').matches(phoneRegExp, 'Phone number is not valid'),
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  note: Yup.string().required('Note is required'),
  interview_date: Yup.string().required('Interview date is required'),
  // resume: Yup.string().required('Resume is required'),
  status: Yup.string().required('Status is required'),
});

export const AddEditAssetFormSchema = Yup.object().shape({
  name: Yup.string(),
  description: Yup.string(),
  image: Yup.string(),
  purchase_date: Yup.date().nullable(),
  purchase_price: Yup.number('Invalid price').nullable(),
  type: Yup.string(),
  bills: Yup.array(),
  vendor_id: Yup.string(),
  make: Yup.string().when('type', {
    is: (type) => VEHICLE.includes(type),
    then: Yup.string().required('Make is required'),
    otherwise: Yup.string(),
  }),
  model: Yup.string().when('type', {
    is: (type) => VEHICLE.includes(type),
    then: Yup.string().required('Model is required'),
    otherwise: Yup.string(),
  }),
  year: Yup.string().when('type', {
    is: (type) => VEHICLE.includes(type),
    then: Yup.string().required('Year is required'),
    otherwise: Yup.string(),
  }),
  vin: Yup.string().when('type', {
    is: (type) => VEHICLE.includes(type),
    then: Yup.string().required('VIN is required'),
    otherwise: Yup.string(),
  }),
  plate_number: Yup.string(),
  city: Yup.string(),
  province: Yup.string(),
  zip_code: Yup.string(),
  mileage: Yup.string(),
  trailer_type: Yup.string(),
});

export const AddEditClientContactSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  phone: Yup.string().required('Phone Number is required').matches(phoneRegExp, 'Phone number is not valid'),
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  position: Yup.string().required('Position is required'),
  notes: Yup.string(),
});

export const AssetNewInspectionModalSchema = Yup.object().shape({
  notes: Yup.string().required('Notes is required'),
  date: Yup.date().required('Purchase Date is required'),
  milage: Yup.string().required('Milage is required'),
  inspected_by: Yup.object().required('Inspected by is required'),
});

export const AssetServiceLogModalSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  cost: Yup.string().nullable(),
  date: Yup.date().required('Purchase Date is required'),
  shop_name: Yup.string().required('shop Name is required'),
  shop_address: Yup.string().nullable(),
  milage: Yup.string(),
  type: Yup.string().required('Type is required'),
  notes: Yup.string().nullable(),
});

export const CommunityFormSchema = Yup.object().shape({
  neighborhood: Yup.string().required('Neighborhood is required'),
  city: Yup.string().required('City is required'),
});

export const AddApplicantFormSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  phone: Yup.string().required('Phone Number is required').matches(phoneRegExp, 'Phone number is not valid'),
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  note: Yup.string().required('Note is required'),
  interviewDate: Yup.date().required('Interview Date is required'),
  documents: Yup.array().max(3, 'Max. 3 files allowed'),
  status: Yup.string(),
});

export const AddTimesheetFormSchema = Yup.object().shape({
  workers: Yup.array().test('is-required', 'Workers are required', (value) => !isEmpty(value)),
  time_in: Yup.string().required('Time in are required'),
  time_out: Yup.string()
    .required('Time out are required')
    .test('is-greater', 'Time out should be greater', (value, schema) => {
      const { time_in } = schema?.parent;
      return moment(value).isSameOrAfter(moment(time_in));
    }),
  description: Yup.string(),
});

export const EditTimesheetFormSchema = Yup.object().shape({
  employee_id: Yup.string().required('Worker Selection is required'),
  time_in: Yup.string().required('Time in are required'),
  time_out: Yup.string()
    .required('Time out are required')
    .test('is-greater', 'Time out should be greater', (value, schema) => {
      const { time_in } = schema?.parent;
      return moment(value).isSameOrAfter(moment(time_in));
    }),
  description: Yup.string(),
});

export const FLHABagResetFormSchema = Yup.object().shape({});

export const FLHASiteCleaningFormSchema = Yup.object().shape({});

export const ApprovalFormSchema = Yup.object().shape({});

export const GeneralFormSchema = Yup.object().shape({});

export const NewJobSchema = Yup.object().shape({
  site_id: Yup.string().required('Site address is required'),
  client_id: Yup.string().when('site_id', {
    is: (value) => !value,
    then: Yup.string(),
    otherwise: Yup.string().required('Company name is required').nullable(),
  }),
  type: Yup.string().required('Job type is required'),
  other_job_description: Yup.string().when('type', {
    is: (value) => value === JOB_TYPE_LABELS.OTHER,
    then: Yup.string().required('Job Description is required'),
    otherwise: Yup.string().nullable(),
  }),
  supervisor_id: Yup.string().required('Supervisor is required').nullable(),
  community: Yup.string().required('Community is required').nullable(),
  workers: Yup.array().min(1).required('Workers are required'),
  date: Yup.string().required('Date is required'),
  time_in: Yup.string().required('Time in are required'),
  time_out: Yup.string()
    .required('Time out are required')
    .test('is-greater', 'Time out should be greater', (value, schema) => {
      const { time_in } = schema?.parent;
      return moment(value).isSameOrAfter(moment(time_in));
    }),
});

export const extraChargeSchema = Yup.object().shape({
  type: Yup.string().required('Type is required'),
  note: Yup.string(),
  price: Yup.number().typeError('Price must be a number').required('Price is required'),
});

export const addEditVendorFormSchema = Yup.object().shape({
  name: Yup.string().required('Vendor Name is required'),
  contact_name: Yup.string().required('Contact Name is required'),
  phone: Yup.string().required('Phone Number is required').matches(phoneRegExp, 'Phone number is not valid'),
  email: Yup.string().email('Email must be a valid email address').required('Vendor Email is required'),
});

export const addEditMaterialFormSchema = Yup.object().shape({
  name: Yup.string('Invalid name').required(),
  unit: Yup.string('Invalid unit'),
  material_category_id: Yup.string('Invalid category').required(),
  price: Yup.number('Invalid price').required(),
});

export const AddEditJobFormSchema = Yup.object().shape({
  name: Yup.string('Invalid name').required(),
  price: Yup.number('Invalid price').required(),
});

export const AddEditClientJobPricingFormSchema = Yup.object().shape({
  name: Yup.string('Invalid name').required(),
  price: Yup.number('Invalid price').required(),
});

export const AddEditClientServicePricingFormSchema = Yup.object().shape({
  name: Yup.string('Invalid name').required(),
  price: Yup.number('Invalid price').required(),
});

export const AddEditClientMaterialPricingFormSchema = Yup.object().shape({
  name: Yup.string('Invalid name').required(),
  price: Yup.number('Invalid price').required(),
});

export const AddEditInvoiceSchema = Yup.object().shape({
  createDate: Yup.string().nullable().required('Create date is required'),
  dueDate: Yup.string().nullable().required('Due date is required'),
  client: Yup.string().required('Client is required'),
});

export const FuelLogsFormSchema = Yup.object().shape({
  date: Yup.string().required('Date is required'),
  mileage: Yup.number().required('Mileage is required').nullable(),
  litres: Yup.number().required('Litres is required').nullable(),
  asset_id: Yup.string().required('Asset selection is required'),
});
