import { createSlice } from '@reduxjs/toolkit';
import { TIMESHEET_ENDPOINT } from 'src/constants/apiConstants';
import { getHeaders } from 'src/utils';
// utils
import axios from 'src/utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  timesheets: [],
  timesheet: null,
  timesheetRaw: null,
};

const slice = createSlice({
  name: 'timesheet',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TIMESHEETS SUCCESS
    getTimesheetsSuccess(state, action) {
      state.isLoading = false;
      state.timesheets = action.payload;
    },

    // GET TIMESHEET BY ID SUCCESS
    getTimesheetSuccess(state, action) {
      state.isLoading = false;
      state.timesheet = action.payload;
    },

    // GET TIMESHEET RAW BY ID SUCCESS
    getTimesheetRawSuccess(state, action) {
      state.timesheetRaw = action.payload;
    },

    // RESET TIMESHEET RAW BY ID SUCCESS
    resetTimesheetRawSuccess(state, action) {
      state.timesheetRaw = action.payload;
    },

    // ADD TIMESHEET SUCCESS
    addTimesheetSuccess(state, action) {
      state.isLoading = false;
      state.timesheets = [...state.timesheets, ...action.payload];
    },

    // EDIT TIMESHEET SUCCESS
    editTimesheetSuccess(state, action) {
      state.isLoading = false;
      state.timesheets = state.timesheets.map((obj) => (obj.id === action.payload.id ? action.payload : obj));
    },

    // DELETE TIMESHEET SUCCESS
    deleteTimesheetSuccess(state, action) {
      state.isLoading = false;
      state.timesheets = state.timesheets.filter((obj) => obj.id !== action.payload.id);
    },

    // UPDATE TIMESHEET STATUS SUCCESS
    updateTimesheetStatusSuccess(state, action) {
      state.isLoading = false;
      state.timesheets = state.timesheets.map((obj) =>
        obj.id === action.payload.id ? { ...obj, status: action.payload.status } : obj
      );
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function addTimesheet(params) {
  return async () => {
    const headers = getHeaders();
    const response = await axios.post(TIMESHEET_ENDPOINT, params, { headers });
    dispatch(slice.actions.addTimesheetSuccess(response?.data?.timesheets));
  };
}

// ----------------------------------------------------------------------

export function getTimesheets() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(TIMESHEET_ENDPOINT);
      dispatch(slice.actions.getTimesheetsSuccess(response?.data?.timesheets || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTimesheet(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${TIMESHEET_ENDPOINT}/${id}`);
      dispatch(slice.actions.getTimesheetsSuccess(response?.data?.timesheet));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTimesheetRaw(id) {
  return async () => {
    try {
      const response = await axios.get(`${TIMESHEET_ENDPOINT}/raw/${id}`);
      dispatch(slice.actions.getTimesheetRawSuccess(response?.data?.timesheet));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function resetTimesheetRaw() {
  return async () => {
    try {
      dispatch(slice.actions.resetTimesheetRawSuccess(null));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function editTimesheet(params) {
  return async () => {
    const { id } = params;
    const headers = getHeaders();
    const response = await axios.put(`${TIMESHEET_ENDPOINT}/${id}`, params, { headers });
    dispatch(slice.actions.editTimesheetSuccess(response?.data?.timesheet));
  };
}

// ----------------------------------------------------------------------

export function deleteTimesheet(id) {
  return async () => {
    const headers = getHeaders();
    const response = await axios.delete(`${TIMESHEET_ENDPOINT}/${id}`, { headers });
    dispatch(slice.actions.deleteTimesheetSuccess(response?.data));
  };
}

// ----------------------------------------------------------------------

export function updateTimesheetStatus(params) {
  return async () => {
    const { id } = params;
    const headers = getHeaders();
    const response = await axios.patch(`${TIMESHEET_ENDPOINT}/${id}`, params, { headers });
    dispatch(slice.actions.updateTimesheetStatusSuccess(response?.data?.timesheet));
  };
}
