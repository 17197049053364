import { createSlice } from '@reduxjs/toolkit';
import { CLIENT_ENDPOINT, CONTACT_ENDPOINT } from 'src/constants/apiConstants';
import { getHeaders } from 'src/utils';
// utils
import axios from 'src/utils/axios';
import { getFileFullName } from 'src/utils/getFileFormat';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  clients: [],
  client: null,
  contacts: [],
  contact: {},
};

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CLIENTS
    getClientsSuccess(state, action) {
      state.isLoading = false;
      state.clients = action.payload;
    },

    // GET CLIENT BY ID
    getClientByIdSuccess(state, action) {
      state.isLoading = false;
      state.client = {
        ...action.payload,
        // services: JSON.parse(action.payload?.services),
        // attachments: (action.payload?.attachments || []).map((attachment) => ({
        //   url: attachment,
        //   name: getFileFullName(attachment),
        // })),
      };
    },

    // ADD CLIENT
    addClientSuccess(state, action) {
      state.isLoading = false;
      state.clients = [
        ...state.clients,
        {
          ...action.payload,
          // notes: JSON.parse(action.payload?.notes),
          // services: JSON.parse(action.payload?.services),
          attachments: (action.payload?.attachments || []).map((attachment) => ({
            url: attachment,
            name: getFileFullName(attachment),
          })),
        },
      ];
    },

    // UPDATE CLIENT BY ID
    updateClientByIdSuccess(state, action) {
      state.client = {
        ...action.payload,
        // services: JSON.parse(action.payload?.services),
        attachments: (action.payload?.attachments || []).map((attachment) => ({
          url: attachment,
          name: getFileFullName(attachment),
        })),
      };
    },

    // ADD CLIENT CONTACT
    addContactSuccess(state, action) {
      state.isLoading = false;
      state.contacts = [...state.contacts, action.payload];
    },

    // GET CLIENT CONTACTS
    getClientContactsSuccess(state, action) {
      state.isLoading = false;
      state.contacts = action.payload;
    },

    // GET CLIENT CONTACT BY ID
    getClientContactByIdSuccess(state, action) {
      state.contact = action.payload;
    },

    // UPDATE CLIENT CONTACT BY ID
    updateClientContactByIdSuccess(state, action) {
      const contacts = state?.contacts?.map((contact) => {
        if (contact?.id === action?.payload?.id) {
          return action.payload;
        }
        return contact;
      });
      state.contacts = contacts;
    },

    // DELETE CLIENT CONTACT BY ID
    deleteClientContactByIdSuccess(state, action) {
      const contacts = state?.contacts?.filter((contact) => contact?.id !== action.payload);
      state.contacts = contacts;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getClients() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CLIENT_ENDPOINT);
      dispatch(slice.actions.getClientsSuccess(response?.data?.clients || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getClientById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CLIENT_ENDPOINT}/${id}`);
      dispatch(slice.actions.getClientByIdSuccess(response?.data?.client));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addClient(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.post(CLIENT_ENDPOINT, params, { headers });
    dispatch(slice.actions.addClientSuccess(response?.data?.client));
  };
}

// ----------------------------------------------------------------------

export function updateClientById(params) {
  return async () => {
    const { id } = params;
    const headers = getHeaders();
    const response = await axios.put(`${CLIENT_ENDPOINT}/${id}`, params, { headers });
    dispatch(slice.actions.updateClientByIdSuccess(response?.data?.client));
  };
}

// ----------------------------------------------------------------------

export function addContact(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.post(CONTACT_ENDPOINT, params, { headers });
    dispatch(slice.actions.addContactSuccess(response?.data?.contact));
  };
}

// ----------------------------------------------------------------------

export function getClientContacts(clientId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONTACT_ENDPOINT}/all/${clientId}`);
      dispatch(slice.actions.getClientContactsSuccess(response?.data?.contacts || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getClientContactById(id) {
  return async () => {
    try {
      const response = await axios.get(`${CONTACT_ENDPOINT}/${id}`);
      dispatch(slice.actions.getClientContactByIdSuccess(response?.data?.contact));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateClientContactById(id, params) {
  return async () => {
    const headers = getHeaders();
    const response = await axios.put(`${CONTACT_ENDPOINT}/${id}`, params, { headers });
    dispatch(slice.actions.updateClientContactByIdSuccess(response?.data?.contact));
  };
}

// ----------------------------------------------------------------------

export function deleteClientContactById(id) {
  return async () => {
    await axios.delete(`${CONTACT_ENDPOINT}/${id}`);
    dispatch(slice.actions.deleteClientContactByIdSuccess(id));
  };
}
