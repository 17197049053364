import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import adminReducer from './slices/admin';
import applicantReducer from './slices/applicant';
import jobReducer from './slices/job';
import assetReducer from './slices/asset';
import vendorReducer from './slices/vendor';
import communityReducer from './slices/community';
import userReducer from './slices/user';
import timesheetReducer from './slices/timesheet';
import statusReducer from './slices/status';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import clientReducer from './slices/client';
import siteReducer from './slices/site';
import pricingReducer from './slices/pricing';
import formsReducer from './slices/forms';
import invoiceReducer from './slices/invoice';
import worksheetReducer from './slices/worksheet';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  admin: adminReducer,
  applicant: applicantReducer,
  job: jobReducer,
  user: userReducer,
  asset: assetReducer,
  vendor: vendorReducer,
  client: clientReducer,
  community: communityReducer,
  calendar: calendarReducer,
  site: siteReducer,
  pricing: pricingReducer,
  timesheet: timesheetReducer,
  status: statusReducer,
  forms: formsReducer,
  invoice: invoiceReducer,
  worksheet: worksheetReducer,
  product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
