// {{ AUTH_ENDPOINTS }}
export const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;
export const LOGIN_ENDPOINT = '/auth/login';
export const ADD_USER = '/auth/add-user';
export const RESET_PASSWORD_WITH_OTP = '/auth/reset-password';
export const FORGOT_PASSWORD = '/auth/reset-password-otp';
export const LOGGED_IN_USER_DETAILS = '/user/me';
export const LOGGED_IN_USER_DASHBOARD_STATS = '/user/dashboard-stats';

// {{ FILE_UPLOAD }}
export const GET_SIGNED_URL = '/file/signed-url';
export const FILE_UPLOAD_ENDPOINT = '/file/upload';

// {{ GENERAL_ENDPOINTS }}
export const USER_ENDPOINT = '/user';
export const COMMUNITIES_ENDPOINT = '/community';

// {{ ASSET_ENDPOINT  }}
export const ASSET_ENDPOINT = '/asset';
export const ASSET_INSPECTION_ENDPOINT = '/asset/inspection';
export const ASSET_MAINTENANCE_ENDPOINT = '/asset/maintenance';

// {{ VENDOR_ENDPOINTS }}
export const VENDOR_ENDPOINT = '/vendor';
export const VENDOR_ASSETS = '/asset/by-vendor';

// {{ JOB_ENDPOINT  }}
export const JOB_ENDPOINT = '/job';
export const JOB_EXTRA_ENDPOINT = '/job/extra';
export const JOB_GARBAGE_ENDPOINT = '/job/garbage';
export const JOB_NOTES_ENDPOINT = '/job/note';
export const JOB_PHOTO_ENDPOINT = '/job/photo';

// {{ APPLICANT_ENDPOINT  }}
export const APPLICANT_ENDPOINT = '/applicant';

// {{ CONFIG_ENDPOINTS }}
export const GET_LIST_OF_CITIES = '/config/list-cities';

// {{ SITE_ENDPOINTS }}
export const SITE_ENDPOINT = '/site';

// {{ CLIENT_ENDPOINTS }}
export const CLIENT_ENDPOINT = '/client';
export const ADD_CLIENT_USER = '/client/add-user';
export const UPDATE_CLIENT_USER_BY_ID = '/client/user?id=';
export const LIST_CLIENT_USERS = '/client/list-users';
export const CONTACT_ENDPOINT = '/contact';

// {{ PRICING_ENDPOINTS }}
export const ADD_PRICING_MATERIAL = '/pricing/material';
export const LIST_PRICING_MATERIALS = '/pricing/materials';
export const LIST_PRICING_MATERIAL_CATAGORIES = '/pricing/material/categories';
export const LIST_PRICING_MATERIAL_NAMES = '/pricing/material/names';

export const CLIENT_PRICING_MATERIAL = '/pricing/client/material';
export const LIST_CLIENT_PRICING_MATERIALS = '/pricing/client/materials';

export const LIST_PRICING_JOB_NAMES = '/pricing/job/names';
export const LIST_PRICING_JOBS = '/pricing/jobs';
export const ADD_PRICING_JOB = '/pricing/job';

export const CLIENT_PRICING_JOB = '/pricing/client/job';
export const LIST_CLIENT_PRICING_JOBS = '/pricing/client/jobs';

export const ADD_PRICING_SERVICE = '/pricing/service';
export const LIST_PRICING_SERVICES = '/pricing/services';
export const LIST_PRICING_SERVICES_NAMES = '/pricing/service/names';

export const CLIENT_PRICING_SERVICE = '/pricing/client/service';
export const LIST_CLIENT_PRICING_SERVICES = '/pricing/client/services';

// {{ INVOICE_ENDPOINTS }}
export const LIST_ALL_INVOICES = '/invoice';

// {{ FORMS_ENDPOINTS }}
export const LIST_REPAIR_REQUESTS = '/form/repair-request';
export const GET_REPAIR_REQUEST_BY_ID = '/request';
export const ADD_REPAIR_REQUESTS = LIST_REPAIR_REQUESTS;
export const UPDATE_REPAIR_REQUEST_BY_ID = GET_REPAIR_REQUEST_BY_ID;

export const LIST_EXPENSES = '/form/expenses';
export const ADD_EXPENSE = '/form/expense';

export const ADD_FUEL_LOG = '/form/fuel-log';
export const LIST_FUEL_LOGS = '/form/fuel-logs';

export const ADD_PAYROLL_REQUEST = '/form/payroll-request';
export const LIST_PAYROLL_REQUESTS = '/form/payroll-requests';

// {{ TIMESHEET_ENDPOINTS }}
export const TIMESHEET_ENDPOINT = '/timesheet';

// {{ STATUS_ENDPOINTS }}
export const STATUS_ENDPOINT = '/status';

// {{ STATUS_ENDPOINTS }}
export const BAG_TRUCK_ENDPOINT = '/worksheet/bag-truck';

// {{ WORKSHEET_ENDPOINTS }}
export const WORKSHEET_ENDPOINT = '/worksheet';
export const WORKSHEET_PHOTO_ENDPOINT = '/worksheet/photo';
export const WORKSHEET_NOTES_ENDPOINT = '/worksheet/note';
