import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { useState } from 'react';

// ----------------------------------------------------------------------

RHFPhoneNumber.propTypes = {
  name: PropTypes.string,
};

export default function RHFPhoneNumber({ name, ...other }) {
  const { control, setValue } = useFormContext();
  const [phoneNumber, setPhoneNumber] = useState('');

  const handlePhoneNumberChange = (event) => {
    let input = event.target.value.replace(/\D/g, '');

    if (input.length > 10) {
      input = input.slice(0, 10);
    }

    const formattedNumber = input.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 $3');

    setPhoneNumber(formattedNumber);

    setValue(name, formattedNumber);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          onChange={handlePhoneNumberChange}
          error={!!error}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  );
}
