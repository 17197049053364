import PropTypes from 'prop-types';
import { AnimatePresence, m } from 'framer-motion';
import { Icon } from '@iconify/react';

import fileFill from '@iconify/icons-eva/file-fill';
import closeFill from '@iconify/icons-eva/close-fill';
// import closeFill from '@iconify/icons-eva/close-fill';
// @mui
import { Box, IconButton, ListItem, ListItemIcon, ListItemText } from '@mui/material';
// utils
import { fData } from '../../../utils/formatNumber';
//
import { varFade } from '../../animate';

// ----------------------------------------------------------------------

MultiFilePreviewRow.propTypes = {
  files: PropTypes.array,
  onRemove: PropTypes.func,
};

export default function MultiFilePreviewRow({ files, onRemove }) {
  if (!files?.length || typeof files === 'string') {
    return null;
  }

  return (
    <AnimatePresence>
      {files.map((file) => (
        <ListItem
          key={file.name}
          component={m.div}
          {...varFade().inRight}
          sx={{
            my: 1,
            py: 0.5,
            px: 2,
            borderRadius: 1,
            border: (theme) => `solid 1px ${theme.palette.divider}`,
            bgcolor: 'background.paper',
          }}
        >
          <ListItemIcon>
            <Icon icon={fileFill} width={32} height={32} />
          </ListItemIcon>
          <ListItemText
            primary={file.name}
            secondary={fData(file.size)}
            primaryTypographyProps={{ variant: 'subtitle2' }}
          />
          <Box>
            <IconButton size="large" onClick={() => onRemove(file)}>
              <Icon icon={closeFill} />
            </IconButton>
          </Box>
        </ListItem>
      ))}
    </AnimatePresence>
  );
}
