import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import {
  ADD_USER,
  FORGOT_PASSWORD,
  LOGGED_IN_USER_DETAILS,
  LOGIN_ENDPOINT,
  RESET_PASSWORD_WITH_OTP,
  GET_SIGNED_URL,
  USER_ENDPOINT,
  LOGGED_IN_USER_DASHBOARD_STATS,
} from 'src/constants/apiConstants';
import { getHeaders } from 'src/utils';
// utils
import axios from 'src/utils/axios';
import { isValidToken, setSession } from 'src/utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  dashboardStats: {},
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, dashboardStats } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      dashboardStats,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  UPDATE_USER_DETAILS: (state, action) => ({
    ...state,
    user: action?.payload?.user,
  }),
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    dashboardState: {},
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  ADDUSER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  EDITUSER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  FORGOTPASSWORDOTP: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  CONFRIMOTP: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  addUser: () => Promise.resolve(),
  editUser: () => Promise.resolve(),
  forgotPasswordOtp: () => Promise.resolve(),
  confirmOtp: () => Promise.resolve(),
  updateLoggedInUserDetails: () => Promise.resolve(),
  getSignedUrl: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const getEditID = localStorage.getItem('editUserid');

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get(LOGGED_IN_USER_DETAILS);
          const { data } = await axios.get(LOGGED_IN_USER_DASHBOARD_STATS);

          const { user } = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
              dashboardStats: data?.data,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post(LOGIN_ENDPOINT, {
      email,
      password,
    });
    const { user } = response.data;

    setSession(user?.token);
    localStorage.setItem('accountType', user?.account_type);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { user } = response.data;

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const addUser = async (
    email,
    password,
    userName,
    firstName,
    lastName,
    cellNumber,
    address,
    city,
    zipCode,
    note,
    hireDate,
    terminationDate,
    typeShow,
    sin,
    wcb,
    gst,
    percentCharge,
    emergContact,
    emergPhone,
    dob
  ) => {
    const employeDetails = {
      hire_date: hireDate,
      termination_date: terminationDate,
      sin,
      wcb,
      gst,
      percent_charge: percentCharge,
      emerg_contact: emergContact,
      emerg_phone: emergPhone,
      dob,
      empl_type: typeShow,
    };

    const payload = {
      email,
      password,
      user_name: userName,
      first_name: firstName,
      last_name: lastName,
      cell_number: cellNumber,
      address,
      city,
      postal_code: zipCode,
      note,
      empl_detail: employeDetails,
    };

    const response = await axios.post(ADD_USER, payload);
    const { user } = response.data;

    dispatch({
      type: 'ADDUSER',
      payload: {
        user,
      },
    });
  };

  const updateLoggedInUserDetails = async (payload) => {
    const headers = getHeaders();
    const response = await axios.put(USER_ENDPOINT, payload, { headers });

    dispatch({
      type: 'UPDATE_USER_DETAILS',
      payload: {
        user: response?.data?.user,
      },
    });
  };

  const editUser = async (
    email,
    password,
    userName,
    firstName,
    lastName,
    cellNumber,
    address,
    city,
    zipCode,
    note,
    hireDate,
    terminationDate,
    typeShow,
    sin,
    wcb,
    gst,
    percentCharge,
    emergContact,
    emergPhone,
    dob
  ) => {
    const employeDetails = {
      hire_date: hireDate,
      termination_date: terminationDate,
      sin,
      wcb,
      gst,
      percent_charge: percentCharge,
      emerg_contact: emergContact,
      emerg_phone: emergPhone,
      dob,
      empl_type: typeShow,
    };

    const payload = {
      email,
      password,
      user_name: userName,
      first_name: firstName,
      last_name: lastName,
      cell_number: cellNumber,
      address,
      city,
      postal_code: zipCode,
      note,
      empl_detail: employeDetails,
    };

    const response = await axios.put(`/user/${getEditID}`, payload);
    const { user } = response.data;

    dispatch({
      type: 'EDITUSER',
      payload: {
        user,
      },
    });
  };

  const forgotPasswordOtp = async (email) => {
    const response = await axios.post(FORGOT_PASSWORD, {
      email,
    });
    const { user } = response.data;

    dispatch({
      type: 'FORGOTPASSWORDOTP',
      payload: {
        user,
      },
    });
  };

  const confirmOtp = async (allCode, password, email) => {
    const response = await axios.patch(RESET_PASSWORD_WITH_OTP, {
      otp: allCode,
      email,
      password,
    });
    const { user } = response.data;

    dispatch({
      type: 'CONFRIMOTP',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const getSignedUrl = async (params) => {
    const headers = getHeaders();
    const response = await axios.post(GET_SIGNED_URL, params, { headers });

    return response;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        updateLoggedInUserDetails,
        addUser,
        editUser,
        forgotPasswordOtp,
        confirmOtp,
        getSignedUrl,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
