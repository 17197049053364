export const API_FAILURE_ERROR_MESSAGE = 'Something went wrong. Please try again after sometime.';

export const ACCOUNT_TYPES = {
  admin: 'ADMIN',
  supervisor: 'SUPERVISOR',
  employee: 'EMPLOYEE',
  contractor: 'CONTRACTOR',
  user: 'USER',
  clientUser: 'CLIENT_USER',
};

export const ACCOUNT_TYPE_LABELS = {
  [ACCOUNT_TYPES.admin]: 'Admin',
  [ACCOUNT_TYPES.clientUser]: 'Client User',
  [ACCOUNT_TYPES.contractor]: 'Contractor',
  [ACCOUNT_TYPES.employee]: 'Employee',
  [ACCOUNT_TYPES.supervisor]: 'Supervisor',
  [ACCOUNT_TYPES.user]: 'User',
};

export const HIRING_STATUS = {
  new: 'NEW',
  pendingInterview: 'PENDING_INTERVIEW',
  hired: 'HIRED',
  notHired: 'NOT_HIRED',
};

export const INVOICE_STATUS = {
  paid: 'PAID',
  unpaid: 'UNPAID',
  overdue: 'OVERDUE',
  draft: 'DRAFT',
};

export const INVOICE_STATUS_LABELS = [
  { label: 'Paid', value: INVOICE_STATUS.paid },
  { label: 'Unpaid', value: INVOICE_STATUS.unpaid },
  { label: 'Overdue', value: INVOICE_STATUS.overdue },
  { label: 'Draft', value: INVOICE_STATUS.draft },
];

export const TIMESHEET_STATUS = {
  pending: 'PENDING',
  approved: 'APPROVED',
  denied: 'DENIED',
};

export const JOB_STATUS_TYPES = {
  notStarted: 'not_started',
  inProgress: 'in_progress',
  outOfDate: 'out_of_date',
  completed: 'completed',
};

export const JOB_STATUS = [
  { label: 'Not Started', value: JOB_STATUS_TYPES.notStarted },
  { label: 'In Progress', value: JOB_STATUS_TYPES.inProgress },
  { label: 'Out Of Date', value: JOB_STATUS_TYPES.outOfDate },
  { label: 'Completed', value: JOB_STATUS_TYPES.completed },
];

export const FORM_STATUS = [
  { label: 'Pending', value: 'PENDING' },
  { label: 'Approved', value: 'APPROVED' },
  { label: 'Denied', value: 'DENIED' },
  { label: 'Paid', value: 'PAID' },
];

export const STATES = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Saskatchewan',
  'Yukon',
];

export const listStates = [
  {
    country: 'Canada',
    states: [
      'Alberta',
      'British Columbia',
      'Manitoba',
      'New Brunswick',
      'Newfoundland',
      'Northwest Territories',
      'Nova Scotia',
      'Nunavut',
      'Ontario',
      'Prince Edward Island',
      'Saskatchewan',
      'Yukon',
    ],
  },
  {
    country: 'USA',
    states: [
      'Alabama',
      'Alaska',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'District Of Columbia',
      'Florida',
      'Georgia',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Pennsylvania',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
    ],
  },
];

export const SAFETY_FORM_TYPES = {
  INJURY_INCIDENT: 'INJURY_INCIDENT',
  MINOR_INJURY: 'MINOR_INJURY',
  TRUCK_TRAILER_INSPECTION: 'TRUCK_TRAILER_INSPECTION',
  FLHA_SITE_CLEANING: 'FLHA_SITE_CLEANING',
  FLHA_BAG_RESET: 'FLHA_BAG_RESET',
};

export const SAFETY_FORM_TYPE_LABELS = {
  [SAFETY_FORM_TYPES.INJURY_INCIDENT]: 'Injury / Incident / Near Miss',
  [SAFETY_FORM_TYPES.MINOR_INJURY]: 'Minor Injury',
  [SAFETY_FORM_TYPES.TRUCK_TRAILER_INSPECTION]: 'Truck & Trailer Inspection',
  [SAFETY_FORM_TYPES.FLHA_SITE_CLEANING]: 'FLHA Site Cleaning',
  [SAFETY_FORM_TYPES.FLHA_BAG_RESET]: 'FLHA Bag Reset',
};

export const SAFETY_FORM_STATUS = {
  APPROVED: 'APPROVED',
  DRAFT: 'DRAFT',
};

export const INSPECTION_TYPES = {
  PRE_INSPECTION: 'PRE_INSPECTION',
  POST_INSPECTION: 'POST_INSPECTION',
};

export const INSPECTION_TYPE_LABELS = {
  [INSPECTION_TYPES.PRE_INSPECTION]: 'Pre Inspection',
  [INSPECTION_TYPES.POST_INSPECTION]: 'Post Inspection',
};

export const MOCK_INVOICE_WORKSHEET_DATA = [
  {
    worksheetNo: '1',
    jobId: 'JOB-1212',
    date: '2023-08-28',
    siteOrAddress: '11-A & 11-J Rayborn Cr.',
    type: 'Site Cleaning',
    price: 200,
    extra: 55,
    total: 255,
    available: true,
  },
  {
    worksheetNo: '2',
    jobId: 'JOB-1213',
    date: '2023-08-28',
    siteOrAddress: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, 38421, Edmonton, CA',
    type: 'Internal Vacuum',
    price: 140,
    extra: 60,
    total: 200,
    available: true,
  },
  {
    worksheetNo: '3',
    jobId: 'JOB-1214',
    date: '2023-08-28',
    siteOrAddress: 'Downtown Vancouver',
    type: 'Grass Maintenance',
    price: 200,
    extra: 0,
    total: 200,
    available: true,
  },
  {
    worksheetNo: '4',
    jobId: 'JOB-1215',
    date: '2023-08-28',
    siteOrAddress: 'Downtown Vancouver',
    type: 'Site Cleaning',
    price: 200,
    extra: 55,
    total: 255,
    available: false,
  },
];
