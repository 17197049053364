import { createSlice } from '@reduxjs/toolkit';
import {
  ADD_EXPENSE,
  ADD_FUEL_LOG,
  ADD_PAYROLL_REQUEST,
  ADD_REPAIR_REQUESTS,
  GET_REPAIR_REQUEST_BY_ID,
  LIST_EXPENSES,
  LIST_FUEL_LOGS,
  LIST_PAYROLL_REQUESTS,
  LIST_REPAIR_REQUESTS,
  UPDATE_REPAIR_REQUEST_BY_ID,
} from 'src/constants/apiConstants';
import { getHeaders } from 'src/utils';
// utils
import axios from 'src/utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  expenses: [],
  expense: {},
  fuelLogs: [],
  fuelLog: {},
  payrollAdvanceRequests: [],
  payrollAdvanceRequest: {},
  preTripInspections: [],
  preTripInspection: {},
  repairRequests: [],
  repairRequest: {},
  suppliesRequests: [],
  suppliesRequest: {},
};

const slice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET REPAIR REQUESTS
    getRepairRequestsSuccess(state, action) {
      state.isLoading = false;
      state.repairRequests = action.payload;
    },

    // GET REPAIR REQUEST BY ID
    getRepairRequestByIdSuccess(state, action) {
      state.isLoading = false;
      state.repairRequest = action.payload;
    },

    // ADD REPAIR REQUEST
    addRepairRequestSuccess(state, action) {
      state.isLoading = false;
      state.repairRequests = [...state.repairRequests, action.payload];
    },

    // UPDATE REPAIR REQUEST BY ID
    updateRepairRequestByIdSuccess(state, action) {
      state.repairRequest = action.payload;
    },

    // GET ALL EXPENSES
    getExpensesSuccess(state, action) {
      state.isLoading = false;
      state.expenses = action.payload;
    },

    // ADD EXPENSE
    addExpenseSuccess(state, action) {
      state.isLoading = false;
      state.expenses = { ...state.expenses, ...action.payload };
    },

    // ADD FUEL LOG
    addFuelLogSuccess(state, action) {
      state.isLoading = false;
      state.fuelLogs = { ...state.fuelLogs, ...action.payload };
    },

    // LIST ALL FUEL LOGS
    getFuelLogsSuccess(state, action) {
      state.isLoading = false;
      state.fuelLogs = action.payload;
    },

    // ADD PAYROLL REQUESTS
    addPayrollRequestSuccess(state, action) {
      state.isLoading = false;
      state.payrollAdvanceRequests = { ...state.payrollAdvanceRequests, ...action.payload };
    },

    // LIST ALL PAYROLL REQUESTS
    getPayrollRequestsSuccess(state, action) {
      state.isLoading = false;
      state.payrollAdvanceRequests = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getRepairRequests() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(LIST_REPAIR_REQUESTS);
      dispatch(slice.actions.getRepairRequestsSuccess(response?.data?.requests || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRepairRequestById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${GET_REPAIR_REQUEST_BY_ID}/${id}`);
      dispatch(slice.actions.getRepairRequestByIdSuccess(response?.data?.request));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addRepairRequest(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.post(ADD_REPAIR_REQUESTS, params, { headers });
    dispatch(slice.actions.addRepairRequestSuccess(response?.data?.request));
  };
}

// ----------------------------------------------------------------------

export function updateRepairRequestById(params) {
  return async () => {
    const headers = getHeaders();
    const response = await axios.put(UPDATE_REPAIR_REQUEST_BY_ID, params, { headers });
    dispatch(slice.actions.updateRepairRequestByIdSuccess(response?.data?.request));
  };
}

// ----------------------------------------------------------------------

export function getExpenses() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(LIST_EXPENSES);
      dispatch(slice.actions.getExpensesSuccess(response?.data?.expenses || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addExpense(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.post(ADD_EXPENSE, params, { headers });
    dispatch(slice.actions.addExpenseSuccess(response?.data?.expense));
  };
}

// ----------------------------------------------------------------------

export function addFuelLog(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.post(ADD_FUEL_LOG, params, { headers });
    dispatch(slice.actions.addFuelLogSuccess(response?.data?.fuelLog));
  };
}

// ----------------------------------------------------------------------

export function getFuelLogs() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(LIST_FUEL_LOGS);
      dispatch(slice.actions.getFuelLogsSuccess(response?.data?.fuelLogs || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addPayrollRequest(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.post(ADD_PAYROLL_REQUEST, params, { headers });
    dispatch(slice.actions.addPayrollRequestSuccess(response?.data?.payroll));
  };
}

// ----------------------------------------------------------------------

export function getPayrollRequests() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(LIST_PAYROLL_REQUESTS);
      dispatch(slice.actions.getPayrollRequestsSuccess(response?.data?.payrolls || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
