/* eslint-disable */
export const getHeaders = () => {
  const accessToken = localStorage.getItem('accessToken');
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return headers;
};

export const getParams = (actualData, updatedData) => {
  let params = {};
  Object.keys(actualData).forEach((prop) => {
    if (updatedData[prop] && actualData[prop] !== updatedData[prop]) {
      params = {
        ...params,
        [prop]: updatedData[prop],
      };
    }
  });
  return params;
};

export const normalise = (value, MIN, MAX) => ((value - MIN) * 100) / (MAX - MIN);

export const isValidImageUrl = (url) => {
  if (typeof url !== 'string') {
    return false;
  }
  return url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp|pdf)(\?(.*))?$/gim) !== null;
};

export const getCurrentUserAccountType = () => {
  const accountType = localStorage.getItem('accountType');
  return accountType;
};

export const isJSON = (str) => {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
};
