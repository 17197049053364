import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

RHFDatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

export default function RHFDatePicker({ name, label, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          label={label}
          value={field.value}
          onChange={(newValue) => {
            field.onChange(newValue);
          }}
          renderInput={(params) => <TextField {...params} fullWidth error={!!error} helperText={error?.message} />}
          {...other}
        />
      )}
    />
  );
}
