import { createSlice } from '@reduxjs/toolkit';
import { COMMUNITIES_ENDPOINT, GET_LIST_OF_CITIES } from 'src/constants/apiConstants';
import { getHeaders } from 'src/utils';
// utils
import axios from 'src/utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  communities: [],
  community: null,
  cities: [],
};

const slice = createSlice({
  name: 'community',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET COMMUNITIES
    getCommunitiesSuccess(state, action) {
      state.isLoading = false;
      state.communities = action.payload;
    },

    // GET CITIES
    getCitiesSuccess(state, action) {
      state.cities = action.payload;
    },

    // GET COMMUNITY
    getCommunitySuccess(state, action) {
      state.isLoading = false;
      state.community = action.payload;
    },

    // ADD COMMUNITY
    addCommunitySuccess(state, action) {
      state.communities = [...state.communities, action.payload];
    },

    // UPDATE COMMUNITY BY ID
    updateCommunityByIdSuccess(state, action) {
      state.community = action.payload;
    },

    // DELETE COMMUNITY BY ID
    deleteCommunityByIdSuccess(state, action) {
      state.communities = state.communities?.filter((community) => community?.id !== action.payload);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getCommunities() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(COMMUNITIES_ENDPOINT);
      dispatch(slice.actions.getCommunitiesSuccess(response?.data?.communities || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCities() {
  return async () => {
    try {
      const response = await axios.get(GET_LIST_OF_CITIES);
      dispatch(slice.actions.getCitiesSuccess(response?.data?.cities || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCommunityById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${COMMUNITIES_ENDPOINT}/${id}`);
      dispatch(slice.actions.getCommunitySuccess(response?.data?.community));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addCommunity(params) {
  return async () => {
    const headers = getHeaders();
    const response = await axios.post(COMMUNITIES_ENDPOINT, params, { headers });
    dispatch(slice.actions.addCommunitySuccess(response?.data?.community));
  };
}

// ----------------------------------------------------------------------

export function updateCommunityById(params) {
  return async () => {
    const headers = getHeaders();
    const response = await axios.put(COMMUNITIES_ENDPOINT, params, { headers });
    dispatch(slice.actions.updateCommunityByIdSuccess(response?.data?.community));
  };
}

// ----------------------------------------------------------------------

export function deleteCommunityById(id) {
  return async () => {
    await axios.delete(`${COMMUNITIES_ENDPOINT}/${id}`);
    dispatch(slice.actions.deleteCommunityByIdSuccess(id));
  };
}
