import { createSlice } from '@reduxjs/toolkit';
import { APPLICANT_ENDPOINT } from 'src/constants/apiConstants';
import { getHeaders } from 'src/utils';
// utils
import axios from 'src/utils/axios';
import { getFileFullName } from 'src/utils/getFileFormat';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  applicants: [],
  applicant: {},
};

const slice = createSlice({
  name: 'applicant',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET APPLICANTS
    getApplicantsSuccess(state, action) {
      state.isLoading = false;
      state.applicants = action.payload;
    },

    // GET APPLICANT BY ID
    getApplicantByIdSuccess(state, action) {
      state.isLoading = false;
      state.applicant = {
        ...action.payload,
        documents: (action.payload?.documents || []).map((document) => ({
          url: document?.url,
          name: getFileFullName(document?.url),
          size: document?.size,
        })),
      };
    },

    // ADD APPLICANT
    addApplicantSuccess(state, action) {
      state.isLoading = false;
      state.applicants = [...state.applicants, action.payload];
    },

    // UPDATE APPLICANT BY ID
    updateApplicantByIdSuccess(state, action) {
      state.applicant = {
        ...action.payload,
        documents: (action.payload?.documents || []).map((document) => ({
          url: document,
          name: getFileFullName(document),
        })),
      };
    },

    // DELETE APPLICANT BY ID
    deleteApplicantByIdSuccess(state, action) {
      state.applicants = state?.applicants.filter((applicant) => applicant?.id !== action.payload);
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getApplicants() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(APPLICANT_ENDPOINT);
      dispatch(slice.actions.getApplicantsSuccess(response?.data?.applicants || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getApplicantById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${APPLICANT_ENDPOINT}/${id}`);
      dispatch(slice.actions.getApplicantByIdSuccess(response?.data?.applicant));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addApplicant(params) {
  return async () => {
    const headers = getHeaders();
    const response = await axios.post(APPLICANT_ENDPOINT, params, { headers });
    dispatch(slice.actions.addApplicantSuccess(response?.data?.applicant));
  };
}

// ----------------------------------------------------------------------

export function updateApplicantById(params) {
  return async () => {
    const headers = getHeaders();
    const response = await axios.put(APPLICANT_ENDPOINT, params, { headers });
    dispatch(slice.actions.updateApplicantByIdSuccess(response?.data?.applicant));
  };
}

// ----------------------------------------------------------------------

export function deleteApplicantById(id) {
  return async () => {
    await axios.delete(`${APPLICANT_ENDPOINT}/${id}`);
    dispatch(slice.actions.deleteApplicantByIdSuccess(id));
  };
}
