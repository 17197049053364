import PropTypes from 'prop-types';
import { Box } from '@mui/material';

Row.propTypes = {
  children: PropTypes.node,
};

/**
 * A horizontal layout primitive.
 *
 * @param {import("@mui/material").BoxProps} props
 */
export default function Row({ children, ...props }) {
  return (
    <Box sx={{ display: 'flex', gap: '0.8rem' }} {...props}>
      {children}
    </Box>
  );
}
