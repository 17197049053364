import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import toLower from 'lodash/toLower';
// hooks
import useAuth from 'src/hooks/useAuth';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
// components
import LoadingScreen from 'src/components/LoadingScreen';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuth();

  if (isAuthenticated && user?.account_type) {
    return <Navigate to={PATH_DASHBOARD.general?.[toLower(user?.account_type)]?.root} />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}
