import { createSlice } from '@reduxjs/toolkit';
import { CLIENT_ENDPOINT, USER_ENDPOINT } from 'src/constants/apiConstants';
// utils
import axios from 'src/utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  clients: [],
  client: null,
  jobs: [],
  job: null,
  users: [],
  user: null,
  properties: [],
  property: null,
};

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CLIENTS
    getClientsSuccess(state, action) {
      state.isLoading = false;
      state.clients = action.payload;
    },

    // GET CLIENT
    getClientSuccess(state, action) {
      state.isLoading = false;
      state.client = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET USER
    getUserSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },

    // GET PROPERTIES
    getPropertiesSuccess(state, action) {
      state.isLoading = false;
      state.properties = action.payload;
    },

    // GET PROPERTY
    getPropertySuccess(state, action) {
      state.isLoading = false;
      state.property = action.payload;
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getClients() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CLIENT_ENDPOINT);
      dispatch(slice.actions.getClientsSuccess(response?.data?.clients || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getClientById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CLIENT_ENDPOINT}/${id}`);
      dispatch(slice.actions.getClientSuccess(response?.data?.client));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUsers() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(USER_ENDPOINT);
      dispatch(slice.actions.getUsersSuccess(response?.data?.users || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${USER_ENDPOINT}/${id}`);
      dispatch(slice.actions.getUserSuccess(response?.data?.user));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
