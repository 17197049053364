import { createSlice } from '@reduxjs/toolkit';
import {
  ADD_CLIENT_USER,
  ADD_USER,
  LIST_CLIENT_USERS,
  UPDATE_CLIENT_USER_BY_ID,
  USER_ENDPOINT,
} from 'src/constants/apiConstants';
// utils
import axios from 'src/utils/axios';
import { getHeaders } from 'src/utils';
import { getFileFullName } from 'src/utils/getFileFormat';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  users: [],
  user: {},
  clientUsers: [],
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET USER BY ID
    getUserByIdSuccess(state, action) {
      state.isLoading = false;
      state.user = {
        ...action.payload,
        attachments: (action.payload?.attachments || []).map((attachment) => ({
          url: attachment,
          name: getFileFullName(attachment),
        })),
      };
    },

    // ADD USER
    addUserSuccess(state, action) {
      state.users = [...state.users, action.payload];
    },

    // UPDATE USER BY ID
    updateUserByIdSuccess(state, action) {
      state.isLoading = false;
      state.user = {
        ...action.payload,
        attachments: (action.payload?.attachments || []).map((attachment) => ({
          url: attachment,
          name: getFileFullName(attachment),
        })),
      };
    },

    // GET CLIENT USERS
    getClientUsersSuccess(state, action) {
      state.isLoading = false;
      state.clientUsers = action.payload;
    },

    // ADD CLIENT USER
    addClientUserSuccess(state, action) {
      state.isLoading = false;
      state.clientUsers = [...state.clientUsers, action.payload];
    },

    // UPDATE CLIENT USER BY ID
    updateClientUserByIdSuccess(state, action) {
      state.isLoading = false;
      state.user = {
        ...action.payload,
        attachments: (action.payload?.attachments || []).map((attachment) => ({
          url: attachment,
          name: getFileFullName(attachment),
        })),
      };
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUsers() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(USER_ENDPOINT);
      dispatch(slice.actions.getUsersSuccess(response?.data?.users || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${USER_ENDPOINT}/${id}`);
      dispatch(slice.actions.getUserByIdSuccess(response?.data?.user || {}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addUser(params) {
  return async () => {
    const headers = getHeaders();
    const response = await axios.post(ADD_USER, params, { headers });
    dispatch(slice.actions.addUserSuccess(response?.data?.user || {}));
  };
}

export function updateUserById({ id, ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());

    const headers = getHeaders();
    const response = await axios.put(`${USER_ENDPOINT}/${id}`, params, { headers });
    dispatch(slice.actions.updateUserByIdSuccess(response?.data?.user || {}));
  };
}

export function getClientUsers(clientId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${LIST_CLIENT_USERS}/${clientId}`);
      dispatch(slice.actions.getClientUsersSuccess(response?.data?.users || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addClientUser(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.post(ADD_CLIENT_USER, params, { headers });
    dispatch(slice.actions.addClientUserSuccess(response?.data?.user || []));
  };
}

export function updateClientUserById(params, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.put(`${UPDATE_CLIENT_USER_BY_ID}/${id}`, params, { headers });
    dispatch(slice.actions.updateClientUserByIdSuccess(response?.data?.user));
  };
}
