import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import toLower from 'lodash/toLower';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

import { PATH_AFTER_LOGIN } from '../config';
import useAuth from '../hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';
import { PATH_DASHBOARD } from './paths';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user } = useAuth();

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        // {
        //   path: 'register',
        //   element: (
        //     <GuestGuard>
        //       <Register />
        //     </GuestGuard>
        //   ),
        // },
        // { path: 'login-unprotected', element: <Login /> },
        // { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'new-password-sub', element: <NewPasswordSub /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <Navigate to={PATH_DASHBOARD.general?.[toLower(user?.account_type)]?.root} replace /> },
        {
          path: 'admin',
          children: [
            { element: <Navigate to="/dashboard/admin/overview" replace />, index: true },
            { path: 'overview', element: <GeneralApp /> },
            { path: 'schedule', element: <ScheduleView /> },
            { path: 'settings', element: <AdminSettings /> },
            {
              path: 'invoicing',
              children: [
                { element: <Navigate to="/dashboard/admin/invoicing/list" replace />, index: true },
                { path: 'list', element: <InvoicingList /> },
                { path: 'create-new', element: <CreateInvoice /> },
                { path: ':id', element: <EditInvoice /> },
              ],
            },
            {
              path: 'system',
              children: [
                { element: <Navigate to="/dashboard/admin/system/assets/list" replace />, index: true },
                {
                  path: 'vendor',
                  children: [
                    { element: <Navigate to="/dashboard/admin/system/vendor/list" replace />, index: true },
                    { path: 'list', element: <VendorList /> },
                    { path: ':id', element: <VendorDetails /> },
                  ],
                },
                {
                  path: 'asset',
                  children: [
                    { element: <Navigate to="/dashboard/admin/system/asset/list" replace />, index: true },
                    { path: ':id', element: <AssetDetails /> },
                    { path: 'list', element: <AssetList /> },
                  ],
                },
                {
                  path: 'communities',
                  children: [
                    { element: <Navigate to="/dashboard/admin/system/communities/list" replace />, index: true },
                    { path: ':id', element: <CommunityProfile /> },
                    { path: 'list', element: <CommunitiesList /> },
                  ],
                },
              ],
            },
            {
              path: 'reports',
              children: [
                { element: <Navigate to="/dashboard/admin/reports/billing/list" replace />, index: true },
                {
                  path: 'billing',
                  children: [
                    { element: <Navigate to="/dashboard/admin/reports/billing/list" replace />, index: true },
                    { path: 'list', element: <BillingReportList /> },
                    { path: ':id', element: <BillingReportList /> },
                  ],
                },
                {
                  path: 'expenses',
                  children: [
                    { element: <Navigate to="/dashboard/admin/reports/expenses/list" replace />, index: true },
                    { path: 'list', element: <ExpensesReportList /> },
                    { path: ':id', element: <ExpensesReportList /> },
                  ],
                },
                {
                  path: 'sites',
                  children: [
                    { element: <Navigate to="/dashboard/admin/reports/sites/list" replace />, index: true },
                    { path: 'list', element: <SitesReportList /> },
                    { path: ':id', element: <SitesReportList /> },
                  ],
                },
                {
                  path: 'timesheet',
                  children: [
                    { element: <Navigate to="/dashboard/admin/reports/timesheet/list" replace />, index: true },
                    { path: 'list', element: <TimesheetReportList /> },
                    { path: ':id', element: <TimesheetReportList /> },
                  ],
                },
                {
                  path: 'costing',
                  children: [
                    { element: <Navigate to="/dashboard/admin/reports/costing/list" replace />, index: true },
                    { path: 'list', element: <CostingReportList /> },
                    { path: ':id', element: <CostingReportList /> },
                  ],
                },
              ],
            },
            {
              path: 'vendor',
              children: [
                { element: <Navigate to="/dashboard/admin/vendor/list" replace />, index: true },
                { path: 'list', element: <VendorList /> },
                { path: ':id', element: <VendorDetails /> },
              ],
            },
            {
              path: 'jobs',
              children: [
                { element: <Navigate to="/dashboard/admin/jobs/list" replace />, index: true },
                { path: 'list', element: <JobsList /> },
                { path: ':id', element: <JobOverview /> },
              ],
            },
            {
              path: 'pricing',
              children: [
                { element: <Navigate to="/dashboard/admin/pricing/job/list" />, index: true },
                {
                  path: 'job',
                  children: [
                    { element: <Navigate to="/dashboard/admin/pricing/job/list" replace />, index: true },
                    { path: 'list', element: <JobListing /> },
                  ],
                },
                {
                  path: 'material',
                  children: [
                    { element: <Navigate to="/dashboard/admin/pricing/material/list" replace />, index: true },
                    { path: 'list', element: <MaterialListing /> },
                  ],
                },
                {
                  path: 'service',
                  children: [
                    { element: <Navigate to="/dashboard/admin/pricing/service/list" replace />, index: true },
                    { path: 'list', element: <ServiceListing /> },
                  ],
                },
              ],
            },
            {
              path: 'forms',
              children: [
                { element: <Navigate to="/dashboard/admin/forms/expense/list" />, index: true },
                {
                  path: 'repair-request',
                  children: [
                    { element: <Navigate to="/dashboard/admin/forms/repair-request/list" replace />, index: true },
                    { path: 'list', element: <RequestList /> },
                    { path: ':id', element: <RequestDetails /> },
                  ],
                },
                {
                  path: 'expense',
                  children: [
                    { element: <Navigate to="/dashboard/admin/forms/expense/list" replace />, index: true },
                    { path: 'list', element: <Expense /> },
                    { path: ':id', element: <ExpenseDetails /> },
                  ],
                },
                {
                  path: 'fuel-logs',
                  children: [
                    { element: <Navigate to="/dashboard/admin/forms/fuel-logs/list" replace />, index: true },
                    { path: 'list', element: <FuelLog /> },
                    { path: ':id', element: <FuelLogDetails /> },
                  ],
                },
                {
                  path: 'payroll-request',
                  children: [
                    { element: <Navigate to="/dashboard/admin/forms/payroll-request/list" replace />, index: true },
                    { path: 'list', element: <PayrollRequest /> },
                    { path: ':id', element: <PayrollRequestDetails /> },
                  ],
                },
                {
                  path: 'pre-trip-inspection',
                  children: [
                    { element: <Navigate to="/dashboard/admin/forms/pre-trip-inspection/list" replace />, index: true },
                    { path: 'list', element: <PreTripInspectionList /> },
                    { path: ':id', element: <PreTripInspectionList /> },
                  ],
                },
                {
                  path: 'supplies-request',
                  children: [
                    { element: <Navigate to="/dashboard/admin/forms/supplies-request/list" replace />, index: true },
                    { path: 'list', element: <SuppliesRequest /> },
                    { path: ':id', element: <SupplyRequestDetails /> },
                  ],
                },
              ],
            },
            {
              path: 'safety-form',
              children: [
                { element: <Navigate to="/dashboard/admin/safety-form/list" replace />, index: true },
                { path: 'list', element: <SafetyFormsList /> },
                { path: 'create-new', element: <CreateSafetyForm /> },
                { path: ':id', element: <SafetyFormDetail /> },
              ],
            },
            {
              path: 'client',
              children: [
                { element: <Navigate to="/dashboard/admin/client/list" replace />, index: true },
                { path: 'list', element: <ClientList /> },
                { path: ':id', element: <ClientProfile /> },
              ],
            },
            {
              path: 'hiring',
              children: [
                { element: <Navigate to="/dashboard/admin/hiring/list" replace />, index: true },
                { path: ':id', element: <AssetDetails /> },
                { path: 'list', element: <AssetList /> },
              ],
            },
            {
              path: 'applicant',
              children: [
                { element: <Navigate to="/dashboard/admin/applicant/list" replace />, index: true },
                { path: 'list', element: <ApplicantList /> },
                { path: ':id', element: <ApplicantDetails /> },
              ],
            },
            {
              path: 'asset',
              children: [
                { element: <Navigate to="/dashboard/admin/asset/list" replace />, index: true },
                { path: ':id', element: <AssetDetails /> },
                { path: 'list', element: <AssetList /> },
              ],
            },
            {
              path: 'timesheet',
              children: [
                { element: <Navigate to="/dashboard/admin/timesheet/list" replace />, index: true },
                { path: ':id', element: <TimesheetList /> },
                { path: 'list', element: <TimesheetList /> },
              ],
            },
            {
              path: 'worksheet',
              children: [
                { element: <Navigate to="/dashboard/admin/worksheet/list" replace />, index: true },
                { path: ':id', element: <WorksheetDetail /> },
                { path: 'list', element: <WorksheetList /> },
                { path: 'new', element: <WorksheetCreate /> },
              ],
            },
            {
              path: 'communities',
              children: [
                { element: <Navigate to="/dashboard/admin/communities/list" replace />, index: true },
                { path: ':id', element: <CommunityProfile /> },
                { path: 'list', element: <CommunitiesList /> },
              ],
            },
            {
              path: 'properties',
              children: [
                { element: <Navigate to="/dashboard/admin/properties/list" replace />, index: true },
                { path: 'list', element: <SitesList /> },
                { path: ':id', element: <SiteOverview /> },
              ],
            },
            {
              path: 'user',
              children: [
                { element: <Navigate to="/dashboard/admin/user/list" replace />, index: true },
                { path: 'list', element: <UserList /> },
                { path: ':id', element: <UserDetails /> },
              ],
            },
          ],
        },
        {
          path: 'employee',
          children: [
            { element: <Navigate to="/dashboard/employee/overview" replace />, index: true },
            { path: 'overview', element: <GeneralApp /> },
            { path: 'schedule', element: <ScheduleView /> },
            { path: 'settings', element: <AdminSettings /> },
            {
              path: 'jobs',
              children: [
                { element: <Navigate to="/dashboard/employee/jobs/list" replace />, index: true },
                { path: 'list', element: <JobsList /> },
                { path: ':id', element: <JobOverview /> },
              ],
            },
            {
              path: 'forms',
              children: [
                { element: <Navigate to="/dashboard/employee/forms/expense/list" />, index: true },
                {
                  path: 'repair-request',
                  children: [
                    { element: <Navigate to="/dashboard/employee/forms/repair-request/list" replace />, index: true },
                    { path: 'list', element: <RequestList /> },
                    { path: ':id', element: <RequestDetails /> },
                  ],
                },
                {
                  path: 'expense',
                  children: [
                    { element: <Navigate to="/dashboard/employee/forms/expense/list" replace />, index: true },
                    { path: 'list', element: <Expense /> },
                    { path: ':id', element: <ExpenseDetails /> },
                  ],
                },
                {
                  path: 'fuel-logs',
                  children: [
                    { element: <Navigate to="/dashboard/employee/forms/fuel-logs/list" replace />, index: true },
                    { path: 'list', element: <FuelLog /> },
                    { path: ':id', element: <FuelLogDetails /> },
                  ],
                },
                {
                  path: 'payroll-request',
                  children: [
                    { element: <Navigate to="/dashboard/employee/forms/payroll-request/list" replace />, index: true },
                    { path: 'list', element: <PayrollRequest /> },
                    { path: ':id', element: <PayrollRequestDetails /> },
                  ],
                },
                {
                  path: 'pre-trip-inspection',
                  children: [
                    {
                      element: <Navigate to="/dashboard/employee/forms/pre-trip-inspection/list" replace />,
                      index: true,
                    },
                    { path: 'list', element: <PreTripInspectionList /> },
                    { path: ':id', element: <PreTripInspectionList /> },
                  ],
                },
                {
                  path: 'supplies-request',
                  children: [
                    { element: <Navigate to="/dashboard/employee/forms/supplies-request/list" replace />, index: true },
                    { path: 'list', element: <SuppliesRequest /> },
                    { path: ':id', element: <SupplyRequestDetails /> },
                  ],
                },
              ],
            },
            {
              path: 'safety-form',
              children: [
                { element: <Navigate to="/dashboard/employee/safety-form/list" replace />, index: true },
                { path: 'list', element: <SafetyFormsList /> },
                { path: 'create-new', element: <CreateSafetyForm /> },
                { path: ':id', element: <SafetyFormsList /> },
              ],
            },
            {
              path: 'timesheet',
              children: [
                { element: <Navigate to="/dashboard/employee/timesheet/list" replace />, index: true },
                { path: ':id', element: <TimesheetList /> },
                { path: 'list', element: <TimesheetList /> },
              ],
            },
            {
              path: 'worksheet',
              children: [
                { element: <Navigate to="/dashboard/employee/worksheet/list" replace />, index: true },
                { path: ':id', element: <WorksheetList /> },
                { path: 'list', element: <WorksheetList /> },
                { path: 'new', element: <WorksheetCreate /> },
              ],
            },
          ],
        },
        {
          path: 'contractor',
          children: [
            { element: <Navigate to="/dashboard/contractor/overview" replace />, index: true },
            { path: 'overview', element: <GeneralApp /> },
            { path: 'schedule', element: <ScheduleView /> },
            { path: 'settings', element: <AdminSettings /> },
            {
              path: 'jobs',
              children: [
                { element: <Navigate to="/dashboard/contractor/jobs/list" replace />, index: true },
                { path: 'list', element: <JobsList /> },
                { path: ':id', element: <JobOverview /> },
              ],
            },
            {
              path: 'forms',
              children: [
                { element: <Navigate to="/dashboard/contractor/forms/expense/list" />, index: true },
                {
                  path: 'repair-request',
                  children: [
                    { element: <Navigate to="/dashboard/contractor/forms/repair-request/list" replace />, index: true },
                    { path: 'list', element: <RequestList /> },
                    { path: ':id', element: <RequestDetails /> },
                  ],
                },
                {
                  path: 'expense',
                  children: [
                    { element: <Navigate to="/dashboard/contractor/forms/expense/list" replace />, index: true },
                    { path: 'list', element: <Expense /> },
                    { path: ':id', element: <ExpenseDetails /> },
                  ],
                },
                {
                  path: 'fuel-logs',
                  children: [
                    { element: <Navigate to="/dashboard/contractor/forms/fuel-logs/list" replace />, index: true },
                    { path: 'list', element: <FuelLog /> },
                    { path: ':id', element: <FuelLogDetails /> },
                  ],
                },
                {
                  path: 'payroll-request',
                  children: [
                    {
                      element: <Navigate to="/dashboard/contractor/forms/payroll-request/list" replace />,
                      index: true,
                    },
                    { path: 'list', element: <PayrollRequest /> },
                    { path: ':id', element: <PayrollRequestDetails /> },
                  ],
                },
                {
                  path: 'pre-trip-inspection',
                  children: [
                    {
                      element: <Navigate to="/dashboard/contractor/forms/pre-trip-inspection/list" replace />,
                      index: true,
                    },
                    { path: 'list', element: <PreTripInspectionList /> },
                    { path: ':id', element: <PreTripInspectionList /> },
                  ],
                },
                {
                  path: 'supplies-request',
                  children: [
                    {
                      element: <Navigate to="/dashboard/contractor/forms/supplies-request/list" replace />,
                      index: true,
                    },
                    { path: 'list', element: <SuppliesRequest /> },
                    { path: ':id', element: <SupplyRequestDetails /> },
                  ],
                },
              ],
            },
            {
              path: 'safety-form',
              children: [
                { element: <Navigate to="/dashboard/contractor/safety-form/list" replace />, index: true },
                { path: 'list', element: <SafetyFormsList /> },
                { path: 'create-new', element: <CreateSafetyForm /> },
                { path: ':id', element: <SafetyFormsList /> },
              ],
            },
            {
              path: 'timesheet',
              children: [
                { element: <Navigate to="/dashboard/contractor/timesheet/list" replace />, index: true },
                { path: ':id', element: <TimesheetList /> },
                { path: 'list', element: <TimesheetList /> },
              ],
            },
            {
              path: 'worksheet',
              children: [
                { element: <Navigate to="/dashboard/contractor/worksheet/list" replace />, index: true },
                { path: ':id', element: <WorksheetList /> },
                { path: 'list', element: <WorksheetList /> },
                { path: 'new', element: <WorksheetCreate /> },
              ],
            },
          ],
        },
        {
          path: 'supervisor',
          children: [
            { element: <Navigate to="/dashboard/supervisor/overview" replace />, index: true },
            { path: 'overview', element: <GeneralApp /> },
            { path: 'schedule', element: <ScheduleView /> },
            { path: 'settings', element: <AdminSettings /> },
            {
              path: 'jobs',
              children: [
                { element: <Navigate to="/dashboard/supervisor/jobs/list" replace />, index: true },
                { path: 'list', element: <JobsList /> },
                { path: ':id', element: <JobOverview /> },
              ],
            },
            {
              path: 'forms',
              children: [
                { element: <Navigate to="/dashboard/supervisor/forms/expense/list" />, index: true },
                {
                  path: 'repair-request',
                  children: [
                    { element: <Navigate to="/dashboard/supervisor/forms/repair-request/list" replace />, index: true },
                    { path: 'list', element: <RequestList /> },
                    { path: ':id', element: <RequestDetails /> },
                  ],
                },
                {
                  path: 'expense',
                  children: [
                    { element: <Navigate to="/dashboard/supervisor/forms/expense/list" replace />, index: true },
                    { path: 'list', element: <Expense /> },
                    { path: ':id', element: <ExpenseDetails /> },
                  ],
                },
                {
                  path: 'fuel-logs',
                  children: [
                    { element: <Navigate to="/dashboard/supervisor/forms/fuel-logs/list" replace />, index: true },
                    { path: 'list', element: <FuelLog /> },
                    { path: ':id', element: <FuelLogDetails /> },
                  ],
                },
                {
                  path: 'payroll-request',
                  children: [
                    {
                      element: <Navigate to="/dashboard/supervisor/forms/payroll-request/list" replace />,
                      index: true,
                    },
                    { path: 'list', element: <PayrollRequest /> },
                    { path: ':id', element: <PayrollRequestDetails /> },
                  ],
                },
                {
                  path: 'pre-trip-inspection',
                  children: [
                    {
                      element: <Navigate to="/dashboard/supervisor/forms/pre-trip-inspection/list" replace />,
                      index: true,
                    },
                    { path: 'list', element: <PreTripInspectionList /> },
                    { path: ':id', element: <PreTripInspectionList /> },
                  ],
                },
                {
                  path: 'supplies-request',
                  children: [
                    {
                      element: <Navigate to="/dashboard/supervisor/forms/supplies-request/list" replace />,
                      index: true,
                    },
                    { path: 'list', element: <SuppliesRequest /> },
                    { path: ':id', element: <SupplyRequestDetails /> },
                  ],
                },
              ],
            },
            {
              path: 'safety-form',
              children: [
                { element: <Navigate to="/dashboard/supervisor/safety-form/list" replace />, index: true },
                { path: 'list', element: <SafetyFormsList /> },
                { path: 'create-new', element: <CreateSafetyForm /> },
                { path: ':id', element: <SafetyFormsList /> },
              ],
            },
            {
              path: 'timesheet',
              children: [
                { element: <Navigate to="/dashboard/supervisor/timesheet/list" replace />, index: true },
                { path: ':id', element: <TimesheetList /> },
                { path: 'list', element: <TimesheetList /> },
              ],
            },
            {
              path: 'worksheet',
              children: [
                { element: <Navigate to="/dashboard/supervisor/worksheet/list" replace />, index: true },
                { path: ':id', element: <WorksheetList /> },
                { path: 'list', element: <WorksheetList /> },
                { path: 'new', element: <WorksheetCreate /> },
              ],
            },
          ],
        },

        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <Navigate to="/auth/login" replace />,
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const NewPasswordSub = Loadable(lazy(() => import('../pages/auth/NewPasswordSub')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

// ADMIN
const AdminSettings = Loadable(lazy(() => import('../pages/dashboard/internal/AdminSettings')));
const ScheduleView = Loadable(lazy(() => import('../pages/dashboard/general/ScheduleView')));

// VENDOR
const VendorList = Loadable(lazy(() => import('../pages/dashboard/internal/vendors/VendorsList')));
const VendorDetails = Loadable(lazy(() => import('../pages/dashboard/internal/vendors/VendorProfile')));

// REPORTS
const BillingReportList = Loadable(
  lazy(() => import('../pages/dashboard/management/reports/billingReport/BillingReportList'))
);
const ExpensesReportList = Loadable(
  lazy(() => import('../pages/dashboard/management/reports/expensesReport/ExpensesReportList'))
);
const SitesReportList = Loadable(
  lazy(() => import('../pages/dashboard/management/reports/sitesReport/SitesReportList'))
);
const TimesheetReportList = Loadable(
  lazy(() => import('../pages/dashboard/management/reports/timesheetReport/TimesheetReportList'))
);
const CostingReportList = Loadable(
  lazy(() => import('../pages/dashboard/management/reports/costingReport/CostingReportList'))
);

// Jobs
const JobsList = Loadable(lazy(() => import('../pages/dashboard/management/jobs/JobsList')));
const JobOverview = Loadable(lazy(() => import('../pages/dashboard/management/jobs/JobOverview')));

// CLIENT
const ClientList = Loadable(lazy(() => import('../pages/dashboard/management/clients/ClientsList')));
const ClientProfile = Loadable(lazy(() => import('../pages/dashboard/management/clients/ClientProfile')));

// COMMUNITIES
const CommunitiesList = Loadable(lazy(() => import('../pages/dashboard/internal/communities/CommunitiesList')));
const CommunityProfile = Loadable(lazy(() => import('../pages/dashboard/internal/communities/CommunityProfile')));

// ASSET
const AssetList = Loadable(lazy(() => import('../pages/dashboard/internal/assets/AssetList')));
const AssetDetails = Loadable(lazy(() => import('../pages/dashboard/internal/assets/AssetProfile')));

// TIMESHEET
const TimesheetList = Loadable(lazy(() => import('../pages/dashboard/general/timesheets/TimesheetList')));

// APPLICANT
const ApplicantList = Loadable(lazy(() => import('../pages/dashboard/internal/applicants/ApplicantList')));
const ApplicantDetails = Loadable(lazy(() => import('../pages/dashboard/internal/applicants/ApplicantProfile')));

// PROPERTIES
const SitesList = Loadable(lazy(() => import('../pages/dashboard/management/sites/SitesList')));
const SiteOverview = Loadable(lazy(() => import('../pages/dashboard/management/sites/SiteOverview')));

// INVOICING
const InvoicingList = Loadable(lazy(() => import('../pages/dashboard/management/invoicing/InvoicingList')));
const CreateInvoice = Loadable(lazy(() => import('../pages/dashboard/management/invoicing/InvoiceCreate')));
const EditInvoice = Loadable(lazy(() => import('../pages/dashboard/management/invoicing/InvoiceDetails')));

// Pricing
const JobListing = Loadable(lazy(() => import('../pages/dashboard/internal/pricing/job/JobListing')));
const MaterialListing = Loadable(lazy(() => import('../pages/dashboard/internal/pricing/material/MaterialListing')));
const ServiceListing = Loadable(lazy(() => import('../pages/dashboard/internal/pricing/service/ServiceListing')));

// FORMS
const Expense = Loadable(lazy(() => import('../pages/dashboard/general/forms/expense/List')));
const ExpenseDetails = Loadable(lazy(() => import('../pages/dashboard/general/forms/expense/Details')));
const FuelLog = Loadable(lazy(() => import('../pages/dashboard/general/forms/fuelLog/List')));
const FuelLogDetails = Loadable(lazy(() => import('../pages/dashboard/general/forms/fuelLog/Details')));
const PayrollRequest = Loadable(lazy(() => import('../pages/dashboard/general/forms/payrollRequest/List')));
const PayrollRequestDetails = Loadable(lazy(() => import('../pages/dashboard/general/forms/payrollRequest/Details')));
const SuppliesRequest = Loadable(lazy(() => import('../pages/dashboard/general/forms/suppliesRequest/List')));
const SupplyRequestDetails = Loadable(lazy(() => import('../pages/dashboard/general/forms/suppliesRequest/Details')));
const RequestList = Loadable(lazy(() => import('../pages/dashboard/general/forms/repairRequest/List')));
const RequestDetails = Loadable(lazy(() => import('../pages/dashboard/general/forms/repairRequest/Details')));
const PreTripInspectionList = Loadable(
  lazy(() => import('../pages/dashboard/general/forms/preTripInspection/preTripInspectionList'))
);

// SAFETY FORMS
const SafetyFormsList = Loadable(lazy(() => import('../pages/dashboard/general/forms/safety/List')));
const CreateSafetyForm = Loadable(lazy(() => import('../pages/dashboard/general/forms/safety/Create')));
const SafetyFormDetail = Loadable(lazy(() => import('../pages/dashboard/general/forms/safety/Details')));

// WORKSHEETS
const WorksheetList = Loadable(lazy(() => import('../pages/dashboard/general/worksheets/WorksheetList')));
const WorksheetCreate = Loadable(lazy(() => import('../pages/dashboard/general/worksheets/CreateWorksheet')));
const WorksheetDetail = Loadable(lazy(() => import('../pages/dashboard/general/worksheets/WorksheetDetails')));

// USER
const UserList = Loadable(lazy(() => import('../pages/dashboard/internal/users/UsersList')));
const UserDetails = Loadable(lazy(() => import('../pages/dashboard/internal/users/UserProfile')));

const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
