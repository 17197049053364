// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  account: getIcon('ic_account'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  users: getIcon('ic_users'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  vendor: getIcon('ic_vendor'),
  client: getIcon('ic_client'),
  asset: getIcon('ic_asset'),
  applicant: getIcon('ic_applicant'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  communities: getIcon('ic_communities'),
  properties: getIcon('ic_properties'),
  settings: getIcon('ic_settings'),
  sites: getIcon('ic_sites'),
  forms: getIcon('ic_forms'),
  timesheet: getIcon('ic_timesheet'),
  jobs: getIcon('ic_jobs'),
  pricing: getIcon('ic_pricing'),
  reports: getIcon('ic_reports'),
  worksheet: getIcon('ic_worksheet'),
  system: getIcon('ic_system'),
  safety: getIcon('ic_safety'),
};

export const adminNavConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.general.admin.overview.root, icon: ICONS.dashboard },
      { title: 'schedule', path: PATH_DASHBOARD.general.admin.schedule, icon: ICONS.calendar },
      { title: 'timesheets', path: PATH_DASHBOARD.general.admin.timesheet.root, icon: ICONS.timesheet },
      {
        title: 'jobs',
        path: PATH_DASHBOARD.general.admin.jobs.root,
        icon: ICONS.jobs,
      },
      { title: 'worksheets', path: PATH_DASHBOARD.general.admin.worksheet.root, icon: ICONS.worksheet },
      {
        title: 'general forms',
        path: PATH_DASHBOARD.general.admin.forms.root,
        icon: ICONS.forms,
        children: [
          {
            title: 'expenses',
            path: PATH_DASHBOARD.general.admin.forms.expense.root,
          },
          {
            title: 'Fuel Logs',
            path: PATH_DASHBOARD.general.admin.forms.fuelLogs.root,
          },
          {
            title: 'Payroll Advance Request',
            path: PATH_DASHBOARD.general.admin.forms.payrollRequest.root,
          },
          // {
          //   title: 'Pre-trip Inspection',
          //   path: PATH_DASHBOARD.general.admin.forms.preTripInspection.root,
          // },
          {
            title: 'repair requests',
            path: PATH_DASHBOARD.general.admin.forms.repairRequests.root,
          },
          {
            title: 'Supplies Request',
            path: PATH_DASHBOARD.general.admin.forms.suppliesRequest.root,
          },
        ],
      },
      { title: 'safety form', path: PATH_DASHBOARD.general.admin.safetyForm.root, icon: ICONS.safety },
    ],
  },
  {
    subheader: 'management',
    items: [
      { title: 'invoicing', path: PATH_DASHBOARD.general.admin.invoicing.root, icon: ICONS.invoice },
      {
        title: 'sites',
        path: PATH_DASHBOARD.general.admin.properties.root,
        icon: ICONS.sites,
      },
      {
        title: 'client',
        path: PATH_DASHBOARD.general.admin.client.root,
        icon: ICONS.client,
      },
      // {
      //   title: 'reports',
      //   path: PATH_DASHBOARD.general.admin.reports.root,
      //   icon: ICONS.reports,
      //   children: [
      //     { title: 'Billing Report', path: PATH_DASHBOARD.general.admin.reports.billingReport.root },
      //     // { title: 'Expenses Report', path: PATH_DASHBOARD.general.admin.reports.expensesReport.root },
      //     // { title: 'Sites Report', path: PATH_DASHBOARD.general.admin.reports.sitesReport.root },
      //     // { title: 'Timesheet Report', path: PATH_DASHBOARD.general.admin.reports.timesheetReport.root },
      //     { title: 'Costing Report', path: PATH_DASHBOARD.general.admin.reports.costingReport.root },
      //   ],
      // },
      {
        title: 'hiring',
        path: PATH_DASHBOARD.general.admin.applicant.root,
        icon: ICONS.applicant,
      },
      {
        title: 'users',
        path: PATH_DASHBOARD.general.admin.user.root,
        icon: ICONS.users,
      },
      {
        title: 'pricing',
        path: PATH_DASHBOARD.general.admin.pricing.root,
        icon: ICONS.pricing,
        children: [
          {
            title: 'Materials',
            path: PATH_DASHBOARD.general.admin.pricing.material.root,
          },
          {
            title: 'Services',
            path: PATH_DASHBOARD.general.admin.pricing.service.root,
          },
          {
            title: 'Job',
            path: PATH_DASHBOARD.general.admin.pricing.job.root,
          },
        ],
      },
      {
        title: 'system',
        path: PATH_DASHBOARD.general.admin.system.root,
        icon: ICONS.system,
        children: [
          {
            title: 'assets',
            path: PATH_DASHBOARD.general.admin.system.asset.root,
          },
          { title: 'Vendors', path: PATH_DASHBOARD.general.admin.system.vendor.root },
          {
            title: 'communities',
            path: PATH_DASHBOARD.general.admin.system.communities.root,
          },
        ],
      },
      { title: 'my account', path: PATH_DASHBOARD.general.admin.settings, icon: ICONS.account },
    ],
  },
];

export const employeeNavConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.general.employee.overview.root, icon: ICONS.dashboard },
      { title: 'schedule', path: PATH_DASHBOARD.general.employee.schedule, icon: ICONS.calendar },
      { title: 'timesheets', path: PATH_DASHBOARD.general.employee.timesheet.root, icon: ICONS.timesheet },
      {
        title: 'jobs',
        path: PATH_DASHBOARD.general.employee.jobs.root,
        icon: ICONS.jobs,
      },
      { title: 'worksheets', path: PATH_DASHBOARD.general.employee.worksheet.root, icon: ICONS.worksheet },
      {
        title: 'general forms',
        path: PATH_DASHBOARD.general.employee.forms.root,
        icon: ICONS.forms,
        children: [
          {
            title: 'expenses',
            path: PATH_DASHBOARD.general.employee.forms.expense.root,
          },
          {
            title: 'Fuel Logs',
            path: PATH_DASHBOARD.general.employee.forms.fuelLogs.root,
          },
          {
            title: 'Payroll Advance Request',
            path: PATH_DASHBOARD.general.employee.forms.payrollRequest.root,
          },
          // {
          //   title: 'Pre-trip Inspection',
          //   path: PATH_DASHBOARD.general.employee.forms.preTripInspection.root,
          // },
          {
            title: 'repair requests',
            path: PATH_DASHBOARD.general.employee.forms.repairRequests.root,
          },
          {
            title: 'Supplies Request',
            path: PATH_DASHBOARD.general.employee.forms.suppliesRequest.root,
          },
        ],
      },
      { title: 'safety form', path: PATH_DASHBOARD.general.employee.safetyForm.root, icon: ICONS.safety },
    ],
  },
  {
    subheader: 'internal',
    items: [{ title: 'my account', path: PATH_DASHBOARD.general.employee.settings, icon: ICONS.account }],
  },
];

export const supervisorNavConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.general.supervisor.overview.root, icon: ICONS.dashboard },
      { title: 'schedule', path: PATH_DASHBOARD.general.supervisor.schedule, icon: ICONS.calendar },
      { title: 'timesheets', path: PATH_DASHBOARD.general.supervisor.timesheet.root, icon: ICONS.timesheet },
      {
        title: 'jobs',
        path: PATH_DASHBOARD.general.supervisor.jobs.root,
        icon: ICONS.jobs,
      },
      { title: 'worksheets', path: PATH_DASHBOARD.general.supervisor.worksheet.root, icon: ICONS.worksheet },
      {
        title: 'general forms',
        path: PATH_DASHBOARD.general.supervisor.forms.root,
        icon: ICONS.forms,
        children: [
          {
            title: 'expenses',
            path: PATH_DASHBOARD.general.supervisor.forms.expense.root,
          },
          {
            title: 'Fuel Logs',
            path: PATH_DASHBOARD.general.supervisor.forms.fuelLogs.root,
          },
          {
            title: 'Payroll Advance Request',
            path: PATH_DASHBOARD.general.supervisor.forms.payrollRequest.root,
          },
          // {
          //   title: 'Pre-trip Inspection',
          //   path: PATH_DASHBOARD.general.supervisor.forms.preTripInspection.root,
          // },
          {
            title: 'repair requests',
            path: PATH_DASHBOARD.general.supervisor.forms.repairRequests.root,
          },
          {
            title: 'Supplies Request',
            path: PATH_DASHBOARD.general.supervisor.forms.suppliesRequest.root,
          },
        ],
      },
      { title: 'safety form', path: PATH_DASHBOARD.general.supervisor.safetyForm.root, icon: ICONS.safety },
    ],
  },
  {
    subheader: 'internal',
    items: [{ title: 'my account', path: PATH_DASHBOARD.general.contractor.settings, icon: ICONS.account }],
  },
];

export const contractorNavConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.general.contractor.overview.root, icon: ICONS.dashboard },
      { title: 'schedule', path: PATH_DASHBOARD.general.contractor.schedule, icon: ICONS.calendar },
      { title: 'timesheets', path: PATH_DASHBOARD.general.contractor.timesheet.root, icon: ICONS.timesheet },
      {
        title: 'jobs',
        path: PATH_DASHBOARD.general.contractor.jobs.root,
        icon: ICONS.jobs,
      },
      { title: 'worksheets', path: PATH_DASHBOARD.general.contractor.worksheet.root, icon: ICONS.worksheet },
      {
        title: 'general forms',
        path: PATH_DASHBOARD.general.contractor.forms.root,
        icon: ICONS.forms,
        children: [
          {
            title: 'expenses',
            path: PATH_DASHBOARD.general.contractor.forms.expense.root,
          },
          {
            title: 'Fuel Logs',
            path: PATH_DASHBOARD.general.contractor.forms.fuelLogs.root,
          },
          {
            title: 'Payroll Advance Request',
            path: PATH_DASHBOARD.general.contractor.forms.payrollRequest.root,
          },
          // {
          //   title: 'Pre-trip Inspection',
          //   path: PATH_DASHBOARD.general.contractor.forms.preTripInspection.root,
          // },
          {
            title: 'repair requests',
            path: PATH_DASHBOARD.general.contractor.forms.repairRequests.root,
          },
          {
            title: 'Supplies Request',
            path: PATH_DASHBOARD.general.contractor.forms.suppliesRequest.root,
          },
        ],
      },
      { title: 'safety form', path: PATH_DASHBOARD.general.contractor.safetyForm.root, icon: ICONS.safety },
    ],
  },
  {
    subheader: 'internal',
    items: [{ title: 'my account', path: PATH_DASHBOARD.general.contractor.settings, icon: ICONS.account }],
  },
];
