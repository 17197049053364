import { createSlice } from '@reduxjs/toolkit';
import { ASSET_ENDPOINT, ASSET_INSPECTION_ENDPOINT, ASSET_MAINTENANCE_ENDPOINT } from 'src/constants/apiConstants';
import { getHeaders } from 'src/utils';
// utils
import axios from 'src/utils/axios';
import { getFileFullName } from 'src/utils/getFileFormat';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  assets: [],
  asset: null,
  inspections: [],
  inspection: {},
  maintenances: [],
  maintenance: {},
  logs: [],
};

const slice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ASSETS SUCCESS
    getAssetsSuccess(state, action) {
      state.isLoading = false;
      state.assets = action.payload;
    },

    // GET ASSET BY ID SUCCESS
    getAssetSuccess(state, action) {
      state.isLoading = false;
      state.asset = {
        ...action.payload,
        bills: (action.payload?.bills || []).map((bill) => ({
          url: bill,
          name: getFileFullName(bill),
        })),
      };
    },

    // GET MAINTENANCE BY ASSET ID SUCCESS
    getMaintenanceByAssetIdSuccess(state, action) {
      state.isLoading = false;
      state.maintenances = action.payload;
    },

    // GET INSPECTION BY ASSET ID SUCCESS
    getInspectionByAssetIdSuccess(state, action) {
      const inspections = action.payload?.map((inspection) => ({
        ...inspection,
        attachments: (inspection?.attachments || []).map((attachment) => ({
          url: attachment,
          name: getFileFullName(attachment),
        })),
      }));
      state.isLoading = false;
      state.inspections = inspections;
    },

    // GET SERVICE LOGS BY ASSET ID SUCCESS
    getServiceLogsByAssetIdSuccess(state, action) {
      state.isLoading = false;
      state.logs = action.payload;
    },

    // ADD ASSET SUCCESS
    addAssetSuccess(state, action) {
      state.isLoading = false;
      state.assets = [...state.assets, action.payload];
    },

    // EDIT ASSET SUCCESS
    editAssetSuccess(state, action) {
      state.isLoading = false;
      state.asset = {
        ...action.payload,
        bills: (action.payload?.bills || []).map((bill) => ({
          url: bill,
          name: getFileFullName(bill),
        })),
      };
    },

    // ADD INSPECTION SUCCESS
    addInspectionSuccess(state, action) {
      state.isLoading = false;
      state.inspections = [
        ...state.inspections,
        {
          ...action.payload,
          attachments: (action.payload?.attachments || []).map((attachment) => ({
            url: attachment,
            name: getFileFullName(attachment),
          })),
        },
      ];
    },

    // ADD MAINTENANCE SERVICE LOG SUCCESS
    addMaintenanceServiceLogSuccess(state, action) {
      state.isLoading = false;
      state.maintenances = [...state.maintenances, action.payload];
    },

    removeInspectionByIdSuccess(state, action) {
      state.inspections = state.inspections?.filter((inspection) => inspection?.id !== action.payload);
    },

    removeMaintenanceByIdSuccess(state, action) {
      state.maintenances = state.maintenances?.filter((maintenance) => maintenance?.id !== action.payload);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getAssets() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(ASSET_ENDPOINT);
      dispatch(slice.actions.getAssetsSuccess(response?.data?.assets || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAssetById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${ASSET_ENDPOINT}/${id}`);
      dispatch(slice.actions.getAssetSuccess(response?.data?.asset));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMaintenanceByAssetId(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${ASSET_ENDPOINT}/${id}/maintenance`);
      dispatch(slice.actions.getMaintenanceByAssetIdSuccess(response?.data?.maintenance));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInspectionByAssetId(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${ASSET_ENDPOINT}/${id}/inspection`);
      dispatch(slice.actions.getInspectionByAssetIdSuccess(response?.data?.inspection));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getServiceLogsByAssetId(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${ASSET_ENDPOINT}/${id}/fuel-logs`);
      dispatch(slice.actions.getServiceLogsByAssetIdSuccess(response?.data?.logs));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addAsset(params) {
  return async () => {
    const headers = getHeaders();
    const response = await axios.post(ASSET_ENDPOINT, params, { headers });
    dispatch(slice.actions.addAssetSuccess(response?.data?.asset));
  };
}

// ----------------------------------------------------------------------

export function editAsset(params) {
  return async () => {
    const { id } = params;
    const headers = getHeaders();
    const response = await axios.put(`${ASSET_ENDPOINT}/${id}`, params, { headers });
    dispatch(slice.actions.editAssetSuccess(response?.data?.asset));
  };
}

// ----------------------------------------------------------------------

export function addInspection(params) {
  return async () => {
    const headers = getHeaders();
    const response = await axios.post(ASSET_INSPECTION_ENDPOINT, params, { headers });
    dispatch(slice.actions.addInspectionSuccess(response?.data?.inspection));
  };
}

// ----------------------------------------------------------------------

export function removeInspectionById(id) {
  return async () => {
    await axios.delete(`${ASSET_INSPECTION_ENDPOINT}/${id}`);
    dispatch(slice.actions.removeInspectionByIdSuccess(id));
  };
}

export function removeMaintenanceById(id) {
  return async () => {
    await axios.delete(`${ASSET_MAINTENANCE_ENDPOINT}/${id}`);
    dispatch(slice.actions.removeMaintenanceByIdSuccess(id));
  };
}

export function addMaintenanceServiceLog(params) {
  return async () => {
    const headers = getHeaders();
    const response = await axios.post(ASSET_MAINTENANCE_ENDPOINT, params, { headers });
    dispatch(slice.actions.addMaintenanceServiceLogSuccess(response?.data?.maintenance));
  };
}
