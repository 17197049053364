export const CLIENT_TYPES = {
  HOME_BUILDER: 'HOME_BUILDER',
  CONTRACTOR: 'CONTRACTOR',
  COMMERCIAL: 'COMMERCIAL',
  RESIDENTIAL: 'RESIDENTIAL',
};

export const CLIENT_CONTACT_POSITION_TYPES = {
  SITE_SUPERVISOR: 'SITE_SUPERVISOR',
  CONSTRUCTION_MANAGER: 'CONSTRUCTION_MANAGER',
  PURCHASING_MANAGER: 'PURCHASING_MANAGER',
  ASSISTANT_SITE_SUPERVISOR: 'ASSISTANT_SITE_SUPERVISOR',
  ACCOUNTS_PAYABLE: 'ACCOUNTS_PAYABLE',
  ACCOUNTS_RECEIVABLE: 'ACCOUNTS_RECEIVABLE',
};

export const ASSET_TYPES = {
  TRUCK: 'TRUCK',
  TRAILER: 'TRAILER',
  POWER_TOOLS: 'POWER_TOOLS',
  OTHER: 'OTHER',
  VEHICLE: 'VEHICLE',
};

export const ASSET_TRAILER_TYPES = {
  DUMP_TRAILER: 'DUMP_TRAILER',
  FLAT_BED_TRAILER: 'FLAT_BED_TRAILER',
  ENCLOSED_TRAILER: 'ENCLOSED_TRAILER',
  OTHER: 'OTHER',
};

export const ASSET_MAINTENANCE_TYPES = {
  SCHEDULED: 'SCHEDULED',
  UNSCHEDULED: 'UNSCHEDULED',
  ACCIDENT: 'ACCIDENT',
  OTHER: 'OTHER',
};

export const SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES = {
  NO_TREATMENT_PATIENT_REQUEST: 'NO_TREATMENT_PATIENT_REQUEST',
  NO_TREATMENT_UNNECESSARY: 'NO_TREATMENT_UNNECESSARY',
  BASIC_FIRST_AID: 'BASIC_FIRST_AID',
  AMBULANCE: 'AMBULANCE',
  TAKEN_TO_HOSPITAL: 'TAKEN_TO_HOSPITAL',
  SEND_TO_OWN_DOCTOR: 'SEND_TO_OWN_DOCTOR',
  OTHER: 'OTHER',
};

export const ASSET_TYPES_VS_LABELS = {
  [ASSET_TYPES.TRUCK]: 'Truck',
  [ASSET_TYPES.TRAILER]: 'Trailer',
  [ASSET_TYPES.POWER_TOOLS]: 'Power Tools',
  [ASSET_TYPES.OTHER]: 'Other',
};

export const ASSET_TRAILER_TYPES_VS_LABELS = {
  [ASSET_TRAILER_TYPES.DUMP_TRAILER]: 'Dump Trailer',
  [ASSET_TRAILER_TYPES.FLAT_BED_TRAILER]: 'Flat Bed Trailer',
  [ASSET_TRAILER_TYPES.ENCLOSED_TRAILER]: 'Enclosed Trailer',
  [ASSET_TRAILER_TYPES.OTHER]: 'Other',
};

export const CLIENT_TYPES_VS_LABELS = {
  [CLIENT_TYPES.HOME_BUILDER]: 'Home Builder',
  [CLIENT_TYPES.CONTRACTOR]: 'Contractor',
  [CLIENT_TYPES.COMMERCIAL]: 'Commercial',
  [CLIENT_TYPES.RESIDENTIAL]: 'Residential',
};

export const CLIENT_CONTACT_POSITION_TYPES_VS_LABELS = {
  [CLIENT_CONTACT_POSITION_TYPES.SITE_SUPERVISOR]: 'Site Supervisor',
  [CLIENT_CONTACT_POSITION_TYPES.CONSTRUCTION_MANAGER]: 'Construction Manager',
  [CLIENT_CONTACT_POSITION_TYPES.PURCHASING_MANAGER]: 'Purchasing Manager',
  [CLIENT_CONTACT_POSITION_TYPES.ASSISTANT_SITE_SUPERVISOR]: 'Assistant Site Supervisor',
  [CLIENT_CONTACT_POSITION_TYPES.ACCOUNTS_PAYABLE]: 'Accounts Payable',
  [CLIENT_CONTACT_POSITION_TYPES.ACCOUNTS_RECEIVABLE]: 'Accounts Receivable',
};

export const ASSET_MAINTENANCE_TYPES_VS_LABELS = {
  [ASSET_MAINTENANCE_TYPES.SCHEDULED]: 'Scheduled',
  [ASSET_MAINTENANCE_TYPES.UNSCHEDULED]: 'Unscheduled',
  [ASSET_MAINTENANCE_TYPES.ACCIDENT]: 'Accident',
  [ASSET_MAINTENANCE_TYPES.OTHER]: 'Other',
};

export const SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES_VS_LABELS = {
  [SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.NO_TREATMENT_PATIENT_REQUEST]: 'No Treatment – At Patient’s Request',
  [SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.NO_TREATMENT_UNNECESSARY]: 'No Treatment – Unnecessary',
  [SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.BASIC_FIRST_AID]: 'Basic First Aid',
  [SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.AMBULANCE]: 'Ambulance',
  [SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.TAKEN_TO_HOSPITAL]: 'Taken to Hospital',
  [SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.SEND_TO_OWN_DOCTOR]: 'Send to Own Doctor',
  [SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.OTHER]: 'Other',
};

export const ASSET_TYPE_OPTIONS = [
  { label: ASSET_TYPES_VS_LABELS[ASSET_TYPES.TRUCK], value: ASSET_TYPES.TRUCK },
  { label: ASSET_TYPES_VS_LABELS[ASSET_TYPES.TRAILER], value: ASSET_TYPES.TRAILER },
  { label: ASSET_TYPES_VS_LABELS[ASSET_TYPES.POWER_TOOLS], value: ASSET_TYPES.POWER_TOOLS },
  { label: ASSET_TYPES_VS_LABELS[ASSET_TYPES.OTHER], value: ASSET_TYPES.OTHER },
];

export const ASSET_TRAILER_TYPE_OPTIONS = [
  { label: ASSET_TRAILER_TYPES_VS_LABELS[ASSET_TRAILER_TYPES.DUMP_TRAILER], value: ASSET_TRAILER_TYPES.DUMP_TRAILER },
  {
    label: ASSET_TRAILER_TYPES_VS_LABELS[ASSET_TRAILER_TYPES.FLAT_BED_TRAILER],
    value: ASSET_TRAILER_TYPES.FLAT_BED_TRAILER,
  },
  {
    label: ASSET_TRAILER_TYPES_VS_LABELS[ASSET_TRAILER_TYPES.ENCLOSED_TRAILER],
    value: ASSET_TRAILER_TYPES.ENCLOSED_TRAILER,
  },
  { label: ASSET_TRAILER_TYPES_VS_LABELS[ASSET_TRAILER_TYPES.OTHER], value: ASSET_TRAILER_TYPES.OTHER },
];

export const CLIENT_TYPE_OPTION = [
  { label: CLIENT_TYPES_VS_LABELS[CLIENT_TYPES.HOME_BUILDER], value: CLIENT_TYPES.HOME_BUILDER },
  { label: CLIENT_TYPES_VS_LABELS[CLIENT_TYPES.CONTRACTOR], value: CLIENT_TYPES.CONTRACTOR },
  { label: CLIENT_TYPES_VS_LABELS[CLIENT_TYPES.COMMERCIAL], value: CLIENT_TYPES.COMMERCIAL },
  { label: CLIENT_TYPES_VS_LABELS[CLIENT_TYPES.RESIDENTIAL], value: CLIENT_TYPES.RESIDENTIAL },
];

export const ASSET_MAINTENANCE_OPTIONS = [
  {
    label: ASSET_MAINTENANCE_TYPES_VS_LABELS[ASSET_MAINTENANCE_TYPES.SCHEDULED],
    value: ASSET_MAINTENANCE_TYPES.SCHEDULED,
  },
  {
    label: ASSET_MAINTENANCE_TYPES_VS_LABELS[ASSET_MAINTENANCE_TYPES.UNSCHEDULED],
    value: ASSET_MAINTENANCE_TYPES.UNSCHEDULED,
  },
  {
    label: ASSET_MAINTENANCE_TYPES_VS_LABELS[ASSET_MAINTENANCE_TYPES.ACCIDENT],
    value: ASSET_MAINTENANCE_TYPES.ACCIDENT,
  },
  { label: ASSET_MAINTENANCE_TYPES_VS_LABELS[ASSET_MAINTENANCE_TYPES.OTHER], value: ASSET_MAINTENANCE_TYPES.OTHER },
];

export const CLIENT_CONTACT_POSITION_OPTIONS = [
  {
    label: CLIENT_CONTACT_POSITION_TYPES_VS_LABELS[CLIENT_CONTACT_POSITION_TYPES.SITE_SUPERVISOR],
    value: CLIENT_CONTACT_POSITION_TYPES.SITE_SUPERVISOR,
  },
  {
    label: CLIENT_CONTACT_POSITION_TYPES_VS_LABELS[CLIENT_CONTACT_POSITION_TYPES.CONSTRUCTION_MANAGER],
    value: CLIENT_CONTACT_POSITION_TYPES.CONSTRUCTION_MANAGER,
  },
  {
    label: CLIENT_CONTACT_POSITION_TYPES_VS_LABELS[CLIENT_CONTACT_POSITION_TYPES.PURCHASING_MANAGER],
    value: CLIENT_CONTACT_POSITION_TYPES.PURCHASING_MANAGER,
  },
  {
    label: CLIENT_CONTACT_POSITION_TYPES_VS_LABELS[CLIENT_CONTACT_POSITION_TYPES.ASSISTANT_SITE_SUPERVISOR],
    value: CLIENT_CONTACT_POSITION_TYPES.ASSISTANT_SITE_SUPERVISOR,
  },
  {
    label: CLIENT_CONTACT_POSITION_TYPES_VS_LABELS[CLIENT_CONTACT_POSITION_TYPES.ACCOUNTS_PAYABLE],
    value: CLIENT_CONTACT_POSITION_TYPES.ACCOUNTS_PAYABLE,
  },
  {
    label: CLIENT_CONTACT_POSITION_TYPES_VS_LABELS[CLIENT_CONTACT_POSITION_TYPES.ACCOUNTS_RECEIVABLE],
    value: CLIENT_CONTACT_POSITION_TYPES.ACCOUNTS_RECEIVABLE,
  },
];

export const SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPE_OPTIONS = [
  {
    label:
      SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES_VS_LABELS[
        SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.NO_TREATMENT_PATIENT_REQUEST
      ],
    value: SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.NO_TREATMENT_PATIENT_REQUEST,
  },
  {
    label:
      SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES_VS_LABELS[
        SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.NO_TREATMENT_UNNECESSARY
      ],
    value: SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.NO_TREATMENT_UNNECESSARY,
  },
  {
    label: SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES_VS_LABELS[SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.AMBULANCE],
    value: SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.AMBULANCE,
  },
  {
    label:
      SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES_VS_LABELS[SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.BASIC_FIRST_AID],
    value: SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.BASIC_FIRST_AID,
  },
  {
    label:
      SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES_VS_LABELS[SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.TAKEN_TO_HOSPITAL],
    value: SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.TAKEN_TO_HOSPITAL,
  },
  {
    label:
      SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES_VS_LABELS[SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.SEND_TO_OWN_DOCTOR],
    value: SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.SEND_TO_OWN_DOCTOR,
  },
  {
    label: SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES_VS_LABELS[SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.OTHER],
    value: SAFETY_FORM_TREATMENT_ADMINISTERTED_TYPES.OTHER,
  },
];

export const SERVICE_OPTION = ['SITE_CLEANING', 'GRASS', 'SNOW', 'WASTE_BAG', 'OTHER'];

export const CONTACT_TYPE = [
  'CONSTRUCTION_MANAGER',
  'SITE_SUPERVISOR',
  'PURCHASING_MANAGER',
  'ASSISTANT_SITE_SUPERVISOR',
];

export const USER_TYPE = ['EMPLOYEE', 'CONTRACTOR', 'SUPERVISOR'];
export const STATUS_TYPE = ['PENDING', 'APPROVED', 'DENIED'];
export const YARD_SIZE = ['S', 'M', 'L', 'XL'];
export const HOUSE_STYLE = ['Bungalow', '2 Storey', 'Split Lev'];
export const STATUS = ['Active', 'Inactive'];
export const CATEGORY = ['ADVERTISING', 'ESTIMATING', 'RENTALS'];
export const VEHICLE = ['TRUCK', 'TRAILER'];
export const REGULAR_HOUR = '8:00';
export const LOT_TYPE = ['REGULAR', 'SPEC', 'SHOW_HOME', 'LOT'];
export const LOT_TYPE_LABELS = {
  REGULAR: 'Regular',
  SPEC: 'Spec',
  SHOW_HOME: 'Show Home',
  LOT: 'Lot',
};

export const JOB_TYPE = ['BAG_RESET', 'BAG_FINAL', 'BAG_SETUP', 'GRASS', 'SITE_CLEAN', 'VACUUM', 'OTHER'];
export const JOB_TYPE_LABELS = {
  all: 'All',
  SITE_CLEAN: 'Site Clean',
  VACUUM: 'Vacuum',
  GRASS: 'Grass',
  BAG_RESET: 'Bag Reset',
  BAG_FINAL: 'Bag Final',
  BAG_SETUP: 'Bag Setup',
  OTHER: 'Other',
};
