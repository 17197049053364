import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from 'src/routes/paths';
// hooks
import useResponsive from 'src/hooks/useResponsive';
// components
import Page from 'src/components/Page';
import Image from 'src/components/Image';
// sections
import { LoginForm } from 'src/sections/auth/login';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled('div')(() => ({
  width: '50%',
  height: '100%',
  borderRadius: 0,
  minWidth: '50%',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  padding: theme.spacing(10, 0, 0, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Image
              visibleByDefault
              disabledEffect
              src="/logo/logo_full.png"
              style={{ height: 'auto', maxWidth: '300px' }}
              alt="login"
              fetchpriority="high"
            />
            <LoginForm />
            {/* Register flow is not enabled for web */}
            {/* {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                  Get started
                </Link>
              </Typography>
            )} */}
            <Typography variant="body2" align="center" sx={{ m: 2 }}>
              © TNN Maintenance &amp; Cleaning Ltd.
            </Typography>
          </ContentStyle>
        </Container>
        {mdUp && (
          <SectionStyle>
            <Box style={{ lineHeight: '0' }}>
              <Image
                visibleByDefault
                disabledEffect
                src="/assets/illustrations/login_img_2.svg"
                style={{ height: '100vh', width: '100%' }}
                alt="login"
                fetchpriority="high"
              />
            </Box>
          </SectionStyle>
        )}
      </RootStyle>
    </Page>
  );
}
