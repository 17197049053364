import { createSlice } from '@reduxjs/toolkit';
import { STATUS_ENDPOINT } from 'src/constants/apiConstants';
import { getHeaders } from 'src/utils';
// utils
import axios from 'src/utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  statuses: [],
};

const slice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET STATUS SUCCESS
    getStatusSuccess(state, action) {
      state.isLoading = false;
      state.statuses = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getStatuses() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(STATUS_ENDPOINT);
      dispatch(slice.actions.getStatusSuccess(response?.data?.statuses || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
