import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { Dialog, Box, Paper } from '@mui/material';
//
import { varFade } from './variants';

// ----------------------------------------------------------------------

DialogAnimate.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  sx: PropTypes.object,
  variants: PropTypes.object,
};

export default function DialogAnimate({ open = false, variants, onClose, children, sx, ...other }) {
  return (
    <AnimatePresence>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={onClose}
        BackdropProps={{
          style: { zIndex: -1, background: 'rgba(235, 235, 235, 0.33)', backdropFilter: 'blur(8.5px)' },
        }}
        PaperComponent={useCallback(
          (props) => (
            <Box
              component={m.div}
              {...(variants ||
                varFade({
                  distance: 120,
                  durationIn: 0.32,
                  durationOut: 0.24,
                  easeIn: 'easeInOut',
                }).inUp)}
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box onClick={onClose} sx={{ width: '100%', height: '100%', position: 'fixed' }} />
              <Paper sx={sx} {...props}>
                {props.children}
              </Paper>
            </Box>
          ),
          []
        )}
        {...other}
      >
        {children}
      </Dialog>
    </AnimatePresence>
  );
}
