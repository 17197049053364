import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Row from '../Row';
import Iconify from '../Iconify';

RFHTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
};

export default function RFHTimePicker({ name, label, ...other }) {
  const { control, watch, setError, clearErrors } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MobileTimePicker
          {...field}
          onChange={field.onChange}
          label={label}
          inputFormat="hh:mm a"
          renderInput={(params) => <TextField {...params} fullWidth error={!!error} helperText={error?.message} />}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'eva:clock-outline'} sx={{ color: 'text.disabled', width: 24, height: 24 }} />
              </InputAdornment>
            ),
          }}
          {...other}
        />
      )}
    />
  );
}
