import { createSlice } from '@reduxjs/toolkit';
import {
  LIST_PRICING_MATERIALS,
  LIST_PRICING_MATERIAL_CATAGORIES,
  LIST_PRICING_MATERIAL_NAMES,
  ADD_PRICING_MATERIAL,
  CLIENT_PRICING_MATERIAL,
  LIST_CLIENT_PRICING_MATERIALS,
  ADD_PRICING_JOB,
  CLIENT_PRICING_JOB,
  LIST_PRICING_JOBS,
  LIST_CLIENT_PRICING_JOBS,
  LIST_PRICING_JOB_NAMES,
  ADD_PRICING_SERVICE,
  LIST_PRICING_SERVICES,
  LIST_PRICING_SERVICES_NAMES,
  CLIENT_PRICING_SERVICE,
  LIST_CLIENT_PRICING_SERVICES,
} from 'src/constants/apiConstants';
import { getHeaders } from 'src/utils';
// utils
import axios from 'src/utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  materials: [],
  material: {},
  materialNames: [],
  materialCatagories: [],
  clientMaterials: [],
  jobs: [],
  clientJobs: [],
  job: {},
  jobNames: [],
  services: [],
  clientServices: [],
  service: {},
  serviceNames: [],
};

const slice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // SYSTEM MATERIALS

    // GET MATERIALS
    getMaterialsSuccess(state, action) {
      state.isLoading = false;
      state.materials = action.payload;
    },

    // ADD MATERIAL
    addPricingMaterialSuccess(state, action) {
      const addedData = action.payload;
      state.materials = [...state.materials, addedData];
    },

    // GET MATERIAL BY ID
    getPricingMaterialByIdSuccess(state, action) {
      state.isLoading = false;
      state.material = action.payload;
    },

    // UPDATE MATERIAL BY ID
    updatePricingMaterialByIdSuccess(state, action) {
      state.material = action.payload;
    },

    // GET MATERIAL CATAGORIES
    getPricingMaterialCatagoriesSuccess(state, action) {
      state.materialCatagories = action.payload;
    },

    // GET MATERIAL NAMES
    getPricingMaterialNamesSuccess(state, action) {
      state.materialNames = action.payload;
    },

    // CLIENT MATERIALS

    // ADD CLIENT MATERIAL
    addClientPricingMaterialSuccess(state, action) {
      state.isLoading = false;
      state.clientMaterials = [...state.clientMaterials, action.payload];
    },

    // GET CLIENT MATERIALS
    getClientPricingMaterialsSuccess(state, action) {
      state.isLoading = false;
      state.clientMaterials = action.payload;
    },

    // UPDATE CLIENT MATERIAL BY ID
    updateClientPricingMaterialByIdSuccess(state, action) {
      state.isLoading = false;
      state.clientMaterials = state.clientMaterials.map((obj) => (obj.id === action.payload.id ? action.payload : obj));
    },

    // DELETE CLIENT MATERIAL BY ID
    deleteClientPricingMaterialByIdSuccess(state, action) {
      state.isLoading = false;
      state.clientMaterials = state.clientMaterials.filter((obj) => obj.id !== action.payload);
    },

    // SYSTEM JOBS

    // GET JOBS
    getPricingJobsSuccess(state, action) {
      state.isLoading = false;
      state.jobs = action.payload;
    },

    // ADD JOB
    addPricingJobSuccess(state, action) {
      const addedData = action.payload;
      state.isLoading = false;
      state.jobs = [...state.jobs, addedData];
    },

    // GET JOB NAMES
    getPricingJobNamesSuccess(state, action) {
      state.jobNames = action.payload;
    },

    // GET JOB BY ID
    getPricingJobByIdSuccess(state, action) {
      state.isLoading = false;
      state.job = action.payload;
    },

    // UPDATE JOB BY ID
    updatePricingJobByIdSuccess(state, action) {
      state.job = action.payload;
    },

    // CLIENT JOB

    // ADD CLIENT JOB
    addClientPricingJobSuccess(state, action) {
      const addedData = action.payload;
      state.isLoading = false;
      state.clientJobs = [...state.clientJobs, addedData];
    },

    // GET CLIENT JOBS
    getClientPricingJobsSuccess(state, action) {
      state.isLoading = false;
      state.clientJobs = action.payload;
    },

    // UPDATE CLIENT JOB BY ID
    updateClientPricingJobByIdSuccess(state, action) {
      state.isLoading = false;
      state.clientJobs = state.clientJobs.map((obj) => (obj.id === action.payload.id ? action.payload : obj));
    },

    // DELETE CLIENT JOB BY ID
    deleteClientPricingJobByIdSuccess(state, action) {
      state.isLoading = false;
      state.clientJobs = state.clientJobs.filter((obj) => obj.id !== action.payload);
    },

    // SYSTEM SERVICE

    // GET SERVICES
    getPricingServicesSuccess(state, action) {
      state.isLoading = false;
      state.services = action.payload;
    },

    // ADD SERVICE
    addPricingServiceSuccess(state, action) {
      const addedData = action.payload;
      state.isLoading = false;
      state.services = [...state.services, addedData];
    },

    // GET SERVICE NAMES
    getPricingServiceNamesSuccess(state, action) {
      state.serviceNames = action.payload;
    },

    // GET SERVICE BY ID
    getPricingServiceByIdSuccess(state, action) {
      state.isLoading = false;
      state.service = action.payload;
    },

    // UPDATE SERVICE BY ID
    updatePricingServiceByIdSuccess(state, action) {
      state.service = action.payload;
    },

    // CLIENT SERVICE

    // ADD CLIENT SERVICE
    addClientPricingServiceSuccess(state, action) {
      state.isLoading = false;
      state.clientServices = [...state.clientServices, action.payload];
    },

    // GET CLIENT SERVICES
    getClientPricingServicesSuccess(state, action) {
      state.isLoading = false;
      state.clientServices = action.payload;
    },

    // UPDATE CLIENT SERVICE BY ID
    updateClientPricingServiceByIdSuccess(state, action) {
      state.isLoading = false;
      state.clientServices = state.clientServices.map((obj) => (obj.id === action.payload.id ? action.payload : obj));
    },

    // DELETE CLIENT SERVICE BY ID
    deleteClientPricingServiceByIdSuccess(state, action) {
      state.isLoading = false;
      state.clientServices = state.clientServices.filter((obj) => obj.id !== action.payload);
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// system material

export function getPricingMaterials() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(LIST_PRICING_MATERIALS);
      dispatch(slice.actions.getMaterialsSuccess(response?.data?.materials || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addPricingMaterial(params) {
  return async () => {
    const headers = getHeaders();
    const response = await axios.post(ADD_PRICING_MATERIAL, params, { headers });
    dispatch(slice.actions.addPricingMaterialSuccess(response?.data?.material));
  };
}

export function getPricingMaterialById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.get(`${ADD_PRICING_MATERIAL}/row/${id}`, { headers });
    dispatch(slice.actions.getPricingMaterialByIdSuccess(response?.data?.material));
  };
}

export function updatePricingMaterialById(params, id) {
  return async () => {
    const headers = getHeaders();
    const response = await axios.put(`${ADD_PRICING_MATERIAL}/${id}`, params, { headers });
    dispatch(slice.actions.updatePricingMaterialByIdSuccess(response?.data?.material));
  };
}

export function getPricingMaterialCatagories() {
  return async () => {
    try {
      const response = await axios.get(LIST_PRICING_MATERIAL_CATAGORIES);
      dispatch(slice.actions.getPricingMaterialCatagoriesSuccess(response?.data?.categories || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPricingMaterialNames() {
  return async () => {
    try {
      const response = await axios.get(LIST_PRICING_MATERIAL_NAMES);
      dispatch(slice.actions.getPricingMaterialNamesSuccess(response?.data?.names || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// client material

export function addClientPricingMaterial(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.post(CLIENT_PRICING_MATERIAL, params, { headers });
    dispatch(slice.actions.addClientPricingMaterialSuccess(response.data.material));
  };
}

export function getClientPricingMaterials(clientId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${LIST_CLIENT_PRICING_MATERIALS}/${clientId}`);
      dispatch(slice.actions.getClientPricingMaterialsSuccess(response.data.materials || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateClientPricingMaterialById(params, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.put(`${CLIENT_PRICING_MATERIAL}/${id}`, params, { headers });
    dispatch(slice.actions.updateClientPricingMaterialByIdSuccess(response.data.material));
  };
}

export function deleteClientPricingMaterialById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.delete(`${CLIENT_PRICING_MATERIAL}/${id}`, { headers });
    dispatch(slice.actions.deleteClientPricingMaterialByIdSuccess(response.data.material));
  };
}

// ----------------------------------------------------------------------

// system job

export function getPricingJobs() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(LIST_PRICING_JOBS);
      dispatch(slice.actions.getPricingJobsSuccess(response?.data?.jobs || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addPricingJob(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.post(ADD_PRICING_JOB, params, { headers });
    dispatch(slice.actions.addPricingJobSuccess(response?.data?.job));
  };
}

export function getPricingJobNames() {
  return async () => {
    try {
      const response = await axios.get(LIST_PRICING_JOB_NAMES);
      dispatch(slice.actions.getPricingJobNamesSuccess(response?.data?.names || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPricingJobById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.get(`${ADD_PRICING_JOB}/${id}`, { headers });
    dispatch(slice.actions.getPricingJobByIdSuccess(response?.data?.job));
  };
}

export function updatePricingJobById(params, id) {
  return async () => {
    const headers = getHeaders();
    const response = await axios.put(`${ADD_PRICING_JOB}/${id}`, params, { headers });
    dispatch(slice.actions.updatePricingJobByIdSuccess(response?.data?.job));
  };
}

// client job

export function addClientPricingJob(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.post(CLIENT_PRICING_JOB, params, { headers });
    dispatch(slice.actions.addClientPricingJobSuccess(response?.data?.job));
  };
}

export function getClientPricingJobs(clientId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${LIST_CLIENT_PRICING_JOBS}/${clientId}`);
      dispatch(slice.actions.getClientPricingJobsSuccess(response?.data?.jobs || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateClientPricingJobById(params, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.put(`${CLIENT_PRICING_JOB}/${id}`, params, { headers });
    dispatch(slice.actions.updateClientPricingJobByIdSuccess(response?.data?.job));
  };
}

export function deleteClientPricingJobById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.delete(`${CLIENT_PRICING_JOB}/${id}`, { headers });
    dispatch(slice.actions.deleteClientPricingJobByIdSuccess(response?.data?.job));
  };
}

// ----------------------------------------------------------------------

// system service

export function getPricingServices() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(LIST_PRICING_SERVICES);
      dispatch(slice.actions.getPricingServicesSuccess(response?.data?.services || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addPricingService(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.post(ADD_PRICING_SERVICE, params, { headers });
    dispatch(slice.actions.addPricingServiceSuccess(response?.data?.service));
  };
}

export function getPricingServiceNames() {
  return async () => {
    try {
      const response = await axios.get(LIST_PRICING_SERVICES_NAMES);
      dispatch(slice.actions.getPricingServiceNamesSuccess(response?.data?.names || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPricingServiceById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.get(`${ADD_PRICING_SERVICE}/${id}`, { headers });
    dispatch(slice.actions.getPricingServiceByIdSuccess(response?.data?.service));
  };
}

export function updatePricingServiceById(params, id) {
  return async () => {
    const headers = getHeaders();
    const response = await axios.put(`${ADD_PRICING_SERVICE}/${id}`, params, { headers });
    dispatch(slice.actions.updatePricingServiceByIdSuccess(response?.data?.service));
  };
}

// client service

export function addClientPricingService(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.post(CLIENT_PRICING_SERVICE, params, { headers });
    dispatch(slice.actions.addClientPricingServiceSuccess(response.data.service));
  };
}

export function getClientPricingServices(clientId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${LIST_CLIENT_PRICING_SERVICES}/${clientId}`);
      dispatch(slice.actions.getClientPricingServicesSuccess(response?.data?.services || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateClientPricingServiceById(params, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.put(`${CLIENT_PRICING_SERVICE}/${id}`, params, { headers });
    dispatch(slice.actions.updateClientPricingServiceByIdSuccess(response.data.service));
  };
}

export function deleteClientPricingServiceById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const headers = getHeaders();
    const response = await axios.delete(`${CLIENT_PRICING_SERVICE}/${id}`, { headers });
    dispatch(slice.actions.deleteClientPricingServiceByIdSuccess(response.data.service));
  };
}
