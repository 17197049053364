// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();

  return (
    <Avatar
      src={user?.image}
      alt={`${user?.first_name} ${user?.last_name}`}
      color={user?.image ? 'default' : createAvatar(`${user?.first_name} ${user?.last_name}`).color}
      {...other}
    >
      {createAvatar(`${user?.first_name} ${user?.last_name}`).name}
    </Avatar>
  );
}
