import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Paper, Typography } from '@mui/material';
// utils
import { fData } from 'src/utils/formatNumber';
//
import { fileData } from '../../file-thumbnail';

// ----------------------------------------------------------------------

RejectionFiles.propTypes = {
  fileRejections: PropTypes.array,
};

export default function RejectionFiles({ fileRejections }) {
  if (!fileRejections.length) {
    return null;
  }

  const tooManyFilesCode = 'too-many-files';
  const isTooManyFilesUploaded = fileRejections?.some((file) => file?.errors?.[0]?.code === tooManyFilesCode);

  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
        borderColor: (theme) => alpha(theme.palette.error.main, 0.24),
      }}
    >
      {isTooManyFilesUploaded ? (
        <Box key={tooManyFilesCode} component="span" sx={{ typography: 'caption' }}>
          Too Many Files
        </Box>
      ) : (
        fileRejections.map(({ file, errors }) => {
          const { path, size } = fileData(file);
          return (
            <Box key={path} sx={{ my: 1 }}>
              <Typography variant="subtitle2" noWrap>
                {path} - {size ? fData(size) : ''}
              </Typography>

              {errors.map((error) => (
                <Box key={error.code} component="span" sx={{ typography: 'caption' }}>
                  - {error.message}
                </Box>
              ))}
            </Box>
          );
        })
      )}
    </Paper>
  );
}
